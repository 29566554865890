import styled from "styled-components";
import bgLines from "../../images/aboutBgLinesCopy.png";
import bgColor from "../../images/Rectangle 23959.png";

export const HowItsWorkContainer = styled.div`
  width: 100%;
  position: relative;
  background: url(${bgLines});
  background-position: center left;
  background-repeat: no-repeat;
  @media (max-width: 1441px) {
    width: 100rem;
  }
  @media (max-width: 1025px) {
    width: 100rem;
  }
  @media only screen and (max-width: 981px) and (min-width: 769px) {
    width: 100%;
  }
  @media (max-width: 768px) {
    width: 100%;
    background: none;
  }
  @media (max-width: 426px) {
    width: 100%;
    background: none;
  }
  @media (max-width: 376px) {
    width: 100%;
    background: none;
  }
  @media (max-width: 321px) {
    background: none;
    width: 100%;
  }
`;

export const BgLine = styled.div`
  position: absolute;
  top: 10px;
  left: 0;
  background: url(${bgLines});
  background-position: center left;
  background-repeat: no-repeat;
  @media (max-width: 426px) {
    display: none;
  }
  @media (max-width: 376px) {
    display: none;
  }
  @media (max-width: 321px) {
    display: none;
  }
`;

export const HowItsWorkWrapper = styled.div`
  padding: 30px 60px;
  width: 100%;
  position: relative;
  @media (max-width: 481px) {
    width: 100%;
    padding: 10px 20px;
  }
  @media (max-width: 426px) {
    width: 100%;
    padding: 20px 20px;
  }
  @media (max-width: 376px) {
    width: 100%;
  }
  @media (max-width: 321px) {
    width: 100%;
  }
`;

export const BgColor = styled.span`
  background: url(${bgColor});
  background-position: center left;
  background-repeat: no-repeat;
`;

export const HowItsWorkTitle = styled.h1`
  font-family: "Manrope";
  font-style: normal;
  font-weight: 800;
  font-size: 80px;
  line-height: 109px;
  color: #1c2541;
  margin-left: 40px;

  @media only screen and (max-width: 768px) and (min-width: 481px) {
    line-height: 69px;
    font-size: 50px;
    margin-left: 0;
    text-align: center;
  }
  /* @media (max-width: 780px) {
  }
  @media (max-width: 426px) {
    line-height: 69px;
    font-size: 60px;
    margin-left: 0;
  } */
  @media only screen and (max-width: 480px) and (min-width: 320px) {
    line-height: 39px;
    font-size: 35px;
    align-items: center;
    padding: 10px 24px;
    margin-left: 0;
    text-align: center;
  }
`;
export const HowItsWorkCardSection = styled.div`
  width: 100%;
  padding: 40px 0px;
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  @media (max-width: 1024px) {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  @media (max-width: 426px) {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
`;
export const CardSection = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  ${(props) => props.mt && `margin-top:50px`}
  ${(props) => props.mb && `margin-bottom:20px`}
  @media (max-width:321px) {
    ${(props) => props.mt && `margin-top:40px`}
  }
`;
export const CardTitle = styled.h2`
  font-family: "Manrope";
  font-style: normal;
  font-weight: 800;
  font-size: 45px;
  line-height: 61px;
  color: #1c2541;
  @media (max-width: 481px) {
    font-size: 40px;
  }
  @media (max-width: 426px) {
    font-size: 35px;
  }
`;
export const CardImage = styled.img`
  width: 300px;
  height: 300px;
  @media (max-width: 481px) {
    width: 230px;
    height: 230px;
  }
  @media (max-width: 426px) {
    width: 200px;
    height: 200px;
  }
  @media (max-width: 376px) {
    width: 180px;
    height: 180px;
  }
  @media (max-width: 321px) {
    width: 160px;
    height: 160px;
  }
`;
export const CardDescription = styled.span`
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  text-align: center;

  color: #485470;
  @media only screen and (max-width: 768px) and (min-width: 481px) {
    font-size: 20px;
    width: 90%;
  }
  @media only screen and (max-width: 480px) and (min-width: 321px) {
    font-size: 18px;
    width: 95%;
  }

  @media (max-width: 320px) {
    width: 100%;
    font-size: 16px;
  }
`;
export const CardDetailWrapper = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  @media (max-width: 1024px) {
    width: 100%;
    margin-bottom: 30px;
  }
  @media (max-width: 426px) {
    width: 100%;
    margin-bottom: 30px;
  }
  @media (max-width: 376px) {
    width: 100%;
    margin-bottom: 30px;
  }
  @media (max-width: 321px) {
    width: 100%;
    margin-bottom: 30px;
  }
`;
export const TitleBackground = styled.div`
  position: absolute;
  width: 290px;
  height: 39px;
  background: #cff800;
  top: 20px;
  margin: 58px 0 0 106px;
  left: 0;
  z-index: -1;

  @media only screen and (max-width: 769px) and (min-width: 481px) {
    width: 150px;
    height: 36px;
    margin: 0px;
  }
  @media only screen and (max-width: 480px) and (min-width: 320px) {
    width: 100px;
    height: 20px;
    margin: 0px 0px 0px 0px;
  }
`;
// export const HowItsWorkWrapper = styled.div``;
// export const HowItsWorkWrapper = styled.div``;
// export const HowItsWorkWrapper = styled.div``;
// export const HowItsWorkWrapper = styled.div``;
// export const HowItsWorkWrapper = styled.div``;
// export const HowItsWorkWrapper = styled.div``;
// export const HowItsWorkWrapper = styled.div``;
// export const HowItsWorkWrapper = styled.div``;
// export const HowItsWorkWrapper = styled.div``;
