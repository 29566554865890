import { useEffect } from "react";
import { logEvent } from "firebase/analytics";
import { analytics } from "./firebase/firebaseConfig";
import LandingPageOne from "./Pages/LandingPageOne/Index";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import BloctoModal from "./components/BloctoModal";
import CheckOut from "./Pages/CheckOut";

function App() {
  let initialOptions;

  useEffect(() => {
    logEvent(analytics, "user-visited");
    // eslint-disable-next-line react-hooks/exhaustive-deps
    initialOptions = {
      "client-id":
        "AYSZHzaXQ7bDhKzl3005N6vZlEnzL_H2_rn7mO4KCEZ--eXxjCu5IOuTb1NHQKWORpQerjBCw5cALbqs",
      currency: "USD",
      intent: "capture",
      "data-client-token":
        "EPu_W9HBmHE9LCvNDf8ri9kofzk6zdzzInZwRQyvJ6xxyMSIlH9Dk1dJuxOmSWQMZ-O5n8SQ5AH5DBfv",
    };
  }, [initialOptions]);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<LandingPageOne />} />
        <Route path="/wallet" element={<BloctoModal />} />
        <Route path="/checkout" element={<CheckOut />} />
      </Routes>
    </Router>
  );
}

export default App;
