import styled from "styled-components";

export const CreatorContainer = styled.div`
  background: #0075eb;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  padding: 16px 16px 110px 16px;
  @media (max-width: 780px) {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 20px;
  }
  /* @media screen and (min-width: 781px) and (max-width: 1300px) {
    padding: 16px;
  } */
  @media only screen and (max-width: 981px) and (min-width: 769px) {
    width: 100%;
  }
  @media screen and (min-width: 768px) and (max-width: 1440px) {
    width: 100%;
  }
`;

export const ArtContainer = styled.div`
  background: #0075eb;
  display: flex;
  align-items: end;
  justify-content: flex-end;
  padding: 16px 0px 108px 110px;
  @media (max-width: 780px) {
    width: 100%;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: end;
    flex-direction: column-reverse;
  }

  @media screen and (min-width: 768px) and (max-width: 1300px) {
    padding: 16px 16px 16px 100px;
  }
  @media only screen and (max-width: 981px) and (min-width: 769px) {
    width: 100%;
    padding: 20px;
  }
  @media screen and (min-width: 781px) and (max-width: 1440px) {
    width: 100rem;
  }
`;

export const SideImg = styled.img`
  width: 759px;
  height: 658px;
  @media (max-width: 768px) {
    width: 300px;
    height: 300px;
    margin-top: 100px;
    ${(props) =>
      props.art &&
      `
     margin-top:240px
    `}
  }
  @media screen and (min-width: 981px) and (max-width: 1300px) {
    width: 459px;
    height: 358px;
    margin-left: 100px;
  }
  @media only screen and (max-width: 980px) and (min-width: 769px) {
    width: 459px;
    height: 358px;
    margin-left: 0px;
  }
`;

export const Title = styled.h2`
  font-family: "Manrope";
  font-style: normal;
  font-weight: 800;
  font-size: 80px;
  line-height: 109px;
  color: #ffffff;
  margin-top: 57px;
  position: relative;
  z-index: 10;
  @media (max-width: 780px) {
    font-size: 49px;
    line-height: 40px;
    margin-left: 10px;
    position: absolute;
    top: -420px;
    left: 0;
    ${(props) =>
      props.art &&
      `
    margin-top:-80px
    `}
  }
  @media screen and (min-width: 781px) and (max-width: 1300px) {
    font-size: 50px;
  }
`;

export const TitleBackGround = styled.div`
  position: absolute;
  width: 282px;
  height: 39px;
  background: #777b62;
  top: 0;
  margin: 45px 0 0 60px;
  left: 0;
  z-index: -1;
  @media (max-width: 780px) {
    width: 170px;
    height: 30px;
    margin: 15px 0 0 40px;
  }
  @media screen and (min-width: 781px) and (max-width: 1300px) {
    width: 200px;
  }
`;

export const TitleBackGroundArt = styled.div`
  position: absolute;
  width: 282px;
  height: 39px;
  background: #777b62;
  top: 0;
  margin: 45px 0 0 15px;
  left: 0;
  z-index: -1;
  @media (max-width: 780px) {
    height: 28px;
    width: 180px;
    margin: 15px 0px 0px 8px;
  }
`;

export const ArtImgContainer = styled.div`
  position: relative;
  @media screen and (min-width: 781px) and (max-width: 1300px) {
    margin-top: 100px;
  }
`;

export const ArtImgText = styled.img`
  position: absolute;
  top: -180px;
  left: 0;
  @media (max-width: 780px) {
    top: 140px;
    width: 250px;
    left: 20px;
  }
  @media screen and (min-width: 781px) and (max-width: 1300px) {
    top: -50px;
    width: 250px;
    left: 20px;
  }
`;

export const Description = styled.p`
  font-family: "Manrope";
  font-style: normal;
  font-weight: 800;
  font-size: 45px;
  line-height: 61px;
  line-height: 61px;
  color: #ffffff;
  max-width: 850px;
  margin-top: 36px;
  @media (max-width: 780px) {
    font-size: 20px;
    line-height: 31px;
    margin-left: 10px;
    padding-left: 10px;
  }
  @media screen and (min-width: 781px) and (max-width: 1300px) {
    font-size: 20px;
    line-height: 31px;
  }
`;

export const Button = styled.button`
  background: #cff800;
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 23.1397px;
  padding: 11px 26px;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 41px;
  color: #1c2541;
  margin-top: 245px;
  cursor: pointer;
  @media (max-width: 780px) {
    margin-top: 20px;
    font-size: 15px;
    padding: 8px 10px;
    line-height: 20px;
    margin-left: 20px;
    margin-bottom: 20px;
  }
  @media screen and (min-width: 781px) and (max-width: 1300px) {
    font-size: 16px;
    margin-top: 20px;
  }
`;
export const ButtonArt = styled.button`
  background: #cff800;
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 23.1397px;
  padding: 11px 26px;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 41px;
  color: #1c2541;
  margin-top: 245px;
  cursor: pointer;
  @media (max-width: 780px) {
    margin-top: 20px;
    font-size: 15px;
    padding: 8px 10px;
    line-height: 20px;
    margin-bottom: 20px;
    margin-left: 10px;
  }
  @media screen and (min-width: 781px) and (max-width: 1300px) {
    font-size: 16px;
    margin-top: 20px;
  }
`;
