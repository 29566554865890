import React, { useState, useEffect } from "react";

import {
  ConnectWalletTitle,
  HeaderContainer,
  HeaderRight,
  HeaderRightIcons,
  HeaderRightProfile,
  Logo,
  LogoContainer,
  LogoWrapper,
  NavigationContainer,
  NavTitle,
  SvgIcon,
  Title,
  TitleImage,
  UserIcon,
  WalletWrapper,
  MobileHeadercontainer,
  LogoContainerMobile,
  TitleImageMobile,
  LogoMobile,
  HamburgerButton,
  FlexBox,
  NavigationContainerMobile,
  NavTitleMobile,
} from "./header-style";
import logo from "../../images/logo.png";
import finger from "../../images/finger.png";
import ModalSignup from "../modal";
import * as fcl from "@onflow/fcl";
import { useLocation } from "react-router-dom";

fcl.config({
  "app.detail.title": "ShotCaller",
  "accessNode.api": "https://rest-mainnet.onflow.org", // Mainnet: "https://rest-mainnet.onflow.org"
  "discovery.wallet": "https://fcl-discovery.onflow.org/authn", // Mainnet: "https://fcl-discovery.onflow.org/authn"
});

export default function Header() {
  const [user, setUser] = useState(null);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [toggle, setToggle] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      window.location = location.hash;
    }
  }, []);

  const onClickHandle = () => {
    setToggle(!toggle);
  };

  const handleBlocto = async () => {
    const response = await fcl.authenticate();
  };

  useEffect(() => {
    fcl.currentUser().subscribe(setUser);
    return () => {
      fcl.unauthenticate();
    };
  }, []);

  return (
    <>
      <HeaderContainer>
        <a href="https://www.playshotcaller.com/" target="_blank">
          <LogoContainer>
            <LogoWrapper>
              <Logo src={logo} alt="logo" />
            </LogoWrapper>
            <TitleImage
              src={require("../../images/ShotCaller 2.svg").default}
            />
          </LogoContainer>
        </a>
        <NavigationContainer>
          <NavTitle href="/#discover" name="#discover">
            Discover
          </NavTitle>

          <NavTitle href="/#rewards">Rewards</NavTitle>
          <NavTitle href="/#creators">Creators</NavTitle>
          <NavTitle href="/#faq">FAQs</NavTitle>
        </NavigationContainer>
        <HeaderRight>
          {/* <HeaderRightIcons>
          <SvgIcon
            src={require("../../images/Search-4.svg").default}
            alt="svg"
          />

          <SvgIcon
            src={require("../../images/image 4 (Traced).svg").default}
            alt="svg"
          />
        </HeaderRightIcons> */}
          <HeaderRightProfile>
            <UserIcon>
              <SvgIcon
                src={require("../../images/finger.white 1.svg").default}
                alt="svg"
              />
            </UserIcon>
            <WalletWrapper onClick={() => handleBlocto()}>
              <ConnectWalletTitle>Connect wallet</ConnectWalletTitle>
            </WalletWrapper>
          </HeaderRightProfile>
        </HeaderRight>
        <ModalSignup
          setIsModalVisible={setIsModalVisible}
          isModalVisible={isModalVisible}
        />
      </HeaderContainer>
      <MobileHeadercontainer>
        <FlexBox>
          <div>
            <a href="https://www.playshotcaller.com/" target="_blank">
              <LogoContainerMobile>
                <LogoWrapper>
                  <LogoMobile src={logo} alt="logo" />
                </LogoWrapper>
                <TitleImageMobile
                  src={require("../../images/ShotCaller 2.svg").default}
                  alt="logo text"
                />
              </LogoContainerMobile>
            </a>
          </div>
          <div>
            <HamburgerButton>
              <div
                className={`container ${toggle ? "change" : null}`}
                onClick={onClickHandle}
                style={{ marginTop: "5px" }}
              >
                <div className="bar1"></div>
                <div className="bar2"></div>
                <div className="bar3"></div>
              </div>
            </HamburgerButton>
          </div>
        </FlexBox>
      </MobileHeadercontainer>
      {toggle && (
        <div
          style={{
            padding: "10px 0 20px 0",
            position: "absolute",
            width: "100vw",
            backgroundColor: "white",
            zIndex: 1,
          }}
        >
          <NavigationContainerMobile>
            <div onClick={onClickHandle} style={{ margin: "15px" }}>
              <NavTitleMobile href="/#discover">Discover</NavTitleMobile>
            </div>
            <div onClick={onClickHandle} style={{ margin: "15px" }}>
              <NavTitleMobile href="/#rewards">Rewards</NavTitleMobile>
            </div>
            <div onClick={onClickHandle} style={{ margin: "15px" }}>
              <NavTitleMobile href="/#creators">Creators</NavTitleMobile>
            </div>
            <div onClick={onClickHandle} style={{ margin: "15px" }}>
              <NavTitleMobile href="/#faq">FAQs</NavTitleMobile>
            </div>
            <div onClick={onClickHandle} style={{ margin: "15px" }}>
              <HeaderRight>
                <HeaderRightProfile>
                  <UserIcon>
                    <SvgIcon
                      src={require("../../images/finger.white 1.svg").default}
                      alt="svg"
                    />
                  </UserIcon>
                  <WalletWrapper onClick={() => handleBlocto()}>
                    <ConnectWalletTitle>connect wallet</ConnectWalletTitle>
                  </WalletWrapper>
                </HeaderRightProfile>
              </HeaderRight>
            </div>
          </NavigationContainerMobile>
        </div>
      )}
    </>
  );
}
