import React, { useEffect, useState } from "react";
import {
  Container,
  Heading,
  ButtonBlock,
  GetStartedButton,
  LearnMore,
  RightArrow,
  Cards,
  CardWrapper,
  Card,
  CardImg,
  CardTitleSection,
  CardTitle,
  CardSold,
  CardUser,
  CardUserStar,
  CardButton,
  HandImage,
  TitleBg,
  CardImageContainer,
  ContainerMobile,
  HandContainer,
  CardSectionConatiner,
  TopHeaderSection,
  ImageCard,
  CenterSection,
  ImageCardContent,
  HandCardContent,
  HandImageSection,
  ImageCardItem,
  CardImage,
  CardAuthor,
  CardAuthorDiv,
  CardAuthorPrice,
  SubTitle,
  ExploreButtonDiv,
  ButtonWrapper,
} from "./getStartedStyle";
import arrowRight from "../../images/arrow-right.svg";
import card1 from "../../images/Wolves 2.png";
import card2 from "../../images/gs-card-2.png";
import card3 from "../../images/finger.png";
// import card3 from "../../images/gs-card-3.png";
// import card4 from "../../images/gs-card-4.png";
import Star from "../../images/star.svg";
import HandImg from "../../images/gs-hand.png";
import ModalSignup from "../modal";
import useWindowDimensions from "../../helper/Dimention";
import { Link } from "react-router-dom";
import ModalSignup2 from "../modal/modal2";

const dataMobile = [
  {
    id: 2,
    img: card3,
    title: "Day-1 Partner Pass",
    sold: "30.5 SC",
    name: "Emily Ray",
    rotation: "9.98deg",
    bottomMinus: "-255px",
    width: true,
    mobileViewShow: true,
    desktop: true,
    tablet: true,
    margin: "100px",
  },
];

const dataDesktop = [
  {
    id: 1,
    img: card1,
    title: "Thristy Wolf Cafe",
    sold: "$10 USD",
    name: "Joey Seraile",
    rotation: "-0.9deg",
    bottomMinus: "0px",
    width: false,
    widthMobile: "200px",
    mobileViewShow: false,
    margin: "-150px",
  },
  {
    id: 2,
    img: card3,
    title: "Day-1 Partner Pass",
    sold: "30.5 SC",
    name: "Emily Ray",
    rotation: "9.98deg",
    bottomMinus: "-255px",
    width: true,
    mobileViewShow: true,
    desktop: true,
    tablet: true,
    margin: "100px",
  },
];

const GetStartedSection = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [wid, setWid] = useState();
  const { height, width } = useWindowDimensions();

  // console.log(wid);
  const data = wid > 780 ? dataDesktop : dataMobile;
  useEffect(() => {
    setWid(width);
  }, [width]);

  return (
    <>
      <Container>
        <Heading>
          Play. Talk SH*T. Collect. <br />{" "}
          <span style={{ position: "relative" }}>
            NFT's
            <TitleBg></TitleBg>
          </span>
        </Heading>
        <ButtonBlock>
          <Link to={"/checkout"}>
            <GetStartedButton>Get Started</GetStartedButton>
          </Link>
          <LearnMore href="#discover">
            Learn more
            <RightArrow src={arrowRight} alt="right arrow" />
          </LearnMore>
        </ButtonBlock>
        <div
        // style={{
        //   display: "flex",
        //   alignItems: "center",
        //   justifyContent: "space-around",
        // }}
        >
          <Cards>
            {dataDesktop.map((item, i) => (
              <CardWrapper
                mobileViewShow={item.mobileViewShow}
                desktop={item.desktop}
              >
                <Card
                  key={i + 1}
                  rotation={item.rotation}
                  bottomMinus={item.bottomMinus}
                  width={item.width}
                  mobileViewShow={item.mobileViewShow}
                  desktop={item.desktop}
                  tablet={item.tablet}
                  mb
                >
                  <CardImageContainer>
                    <CardImg src={item.img} width={item.width} alt="card nft" />
                  </CardImageContainer>
                  <CardTitleSection>
                    <CardTitle>{item.title}</CardTitle>
                    <CardSold>{item.sold}</CardSold>
                  </CardTitleSection>
                  <CardUser>
                    {item.name} <CardUserStar src={Star} alt="user star" />{" "}
                  </CardUser>
                  <Link to={"/checkout"}>
                    <CardButton>
                      Explore now <img src={arrowRight} alt="button arrow" />
                    </CardButton>
                  </Link>
                </Card>
              </CardWrapper>
            ))}
          </Cards>
          <HandImage src={HandImg} alt="hand image" />
        </div>

        <ModalSignup
          setIsModalVisible={setIsModalVisible}
          isModalVisible={isModalVisible}
        />
      </Container>
      <MobileViewCards />

      {/* mobile view to 1300 */}

      <ContainerMobile>
        <Heading>
          Play. Talk SH*T. Collect. <br />{" "}
          <span style={{ position: "relative" }}>
            NFT's
            <TitleBg></TitleBg>
          </span>
        </Heading>
        <ButtonBlock>
          <Link to={"/checkout"}>
            <GetStartedButton>Get Started</GetStartedButton>
          </Link>
          <LearnMore href="#discover">
            Learn more
            <RightArrow src={arrowRight} alt="right arrow" />
          </LearnMore>
        </ButtonBlock>
        <Cards>
          {data.map((item, i) => (
            <CardWrapper key={i}>
              <Card
                rotation={item.rotation}
                bottomMinus={item.bottomMinus}
                mobileViewShow={item.mobileViewShow}
                desktop={item.desktop}
                tablet={item.tablet}
                margin={item?.margin}
                mb
              >
                <CardImageContainer>
                  <CardImg src={item.img} alt="card nft" />
                </CardImageContainer>
                <CardTitleSection>
                  <CardTitle>{item.title}</CardTitle>
                  <CardSold>{item.sold}</CardSold>
                </CardTitleSection>
                <CardUser>
                  {item.name} <CardUserStar src={Star} alt="user star" />{" "}
                </CardUser>
                <Link to={"/checkout"}>
                  <CardButton>
                    Explore now <img src={arrowRight} alt="button arrow" />
                  </CardButton>
                </Link>
              </Card>
            </CardWrapper>
          ))}
          <HandContainer>
            <HandImage src={HandImg} alt="hand image" />
            <ModalSignup
              setIsModalVisible={setIsModalVisible}
              isModalVisible={isModalVisible}
            />
          </HandContainer>
        </Cards>
      </ContainerMobile>
    </>
  );
};

export default GetStartedSection;

export const MobileViewCards = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisible2, setIsModalVisible2] = useState(false);

  // const PopUp = () => {
  //   setTimeout(() => {
  //     setIsModalVisible2(true);
  //   }, 1000);
  // };

  // PopUp();
  useEffect(() => {
    setIsModalVisible2(true);
  }, []);

  return (
    <div>
      <CardSectionConatiner>
        <TopHeaderSection>
          <Heading>
            Play. Talk SH*T. Collect. <br />{" "}
            <span style={{ position: "relative" }}>
              NFT's
              <TitleBg></TitleBg>
            </span>
          </Heading>
          <ButtonBlock>
            <Link to={"/checkout"}>
              <GetStartedButton>Get Started</GetStartedButton>
            </Link>
            <LearnMore href="#discover">
              Learn more
              <RightArrow src={arrowRight} alt="right arrow" />
            </LearnMore>
          </ButtonBlock>
        </TopHeaderSection>
        <CenterSection>
          <ImageCard>
            <ImageCardContent>
              <ImageCardItem>
                <CardImage src={card3} />
                <CardAuthorDiv>
                  <CardAuthor>Day-1 Partner Pass</CardAuthor>
                  <CardAuthorPrice>30.5 SC</CardAuthorPrice>
                </CardAuthorDiv>
                <SubTitle>
                  Emily Ray <CardUserStar src={Star} alt="user star" />
                </SubTitle>
                <Link to={"/checkout"}>
                  <ExploreButtonDiv>
                    <ButtonWrapper>Explore now</ButtonWrapper>
                    <img src={arrowRight} alt="button arrow" />
                  </ExploreButtonDiv>
                </Link>
              </ImageCardItem>
            </ImageCardContent>
            <HandCardContent>
              <HandImageSection src={HandImg} />
              <ModalSignup
                setIsModalVisible={setIsModalVisible}
                isModalVisible={isModalVisible}
              />
              <ModalSignup2
                setIsModalVisible={setIsModalVisible2}
                isModalVisible={isModalVisible2}
                title={"Get MSP NFT Project Updates"}
              />
            </HandCardContent>
          </ImageCard>
        </CenterSection>
      </CardSectionConatiner>
    </div>
  );
};
