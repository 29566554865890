import styled from "styled-components";

export const FooterContainer = styled.div`
  position: relative;
  width: 100%;
  height: 924px;
  display: flex;
  align-items: center;
  padding: 40px 120px 120px 120px;
  background: #485470;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  color: white;

  @media (max-width: 1441px) {
    width: 100rem;
    padding: 0px;
  }
  @media (max-width: 1025px) {
    width: 100rem;
    padding: 0px;
  }
  @media (max-width: 981px) {
    width: 100rem;
    padding: 0px;
    display: none;
  }
  @media (max-width: 480px) {
    width: 100rem;
    padding: 0px;
  }
  @media (max-width: 376px) {
    width: 100rem;
    padding: 0px;
  }
  @media (max-width: 321px) {
    width: 80rem;
  }
`;

export const FooterContainerMobile = styled.div`
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 10px;
  background: #485470;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  color: white;
  @media (min-width: 981px) {
    display: none;
  }
`;

export const FooterWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: start;
`;
export const FooterWrapperLeft = styled.div`
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const FlexBoxWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  @media (max-width: 497px) {
    flex-wrap: nowrap;
    justify-content: center;
    flex-direction: column;
  }
`;

export const SvgIcon = styled.img``;
export const FooterLeftTitle = styled.span`
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 23px;
  margin-top: 110px;
  @media (max-width: 780px) {
    margin: 20px;
    text-align: center;
  }
`;
export const FooterLeftIconContainer = styled.div`
  margin-top: 100px;
`;
export const FooterIcon = styled.img`
  width: 227px;
  height: 143px;
  @media (max-width: 780px) {
    margin: 20px;
    width: 200px;
    height: 143px;
  }
`;

export const FooterWrapperRight = styled.div`
  width: 50%;
  height: 55%;
  display: flex;
  align-items: start;
  justify-content: space-around;
  margin-top: 120px;
`;
export const FooterRightItem = styled.div`
  display: flex;
  align-items: start;
  flex-direction: column;
  @media (max-width: 780px) {
    margin: 20px;
    text-align: center;
  }
  @media (max-width: 497px) {
    align-items: center;
  }
`;
export const FooterRightItemTitle = styled.h4`
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  /* identical to box height */
  margin-bottom: 20px;

  color: #36f599;
`;
export const FooterRightItemItemName = styled.span`
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 32px;
  /* or 200% */
  margin-top: 10px;
  color: #ffffff;
`;

// contact us conatiner

export const ContactUsContainer = styled.div`
  position: absolute;
  top: -10%;
  left: 20%;
  right: 20%;
  width: 60%;
  height: 183.26px;
  background-color: #36f599;
  border-radius: 10px;
  padding: 50px;
  display: flex;
  align-items: center;
  justify-content: start;
`;

export const ContactUsContainerMobile = styled.div`
  background-color: #36f599;
  border-radius: 10px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  margin-top: -100px;
  @media only screen and (max-width: 981px) and (min-width: 781px) {
    width: 80%;
  }
`;

export const ContactUsWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 10px;
`;
export const ContactLeftItem = styled.div`
  width: 40%;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 45px;
  /* text-align: center; */
  /* or 153% */

  display: flex;
  align-items: center;
  letter-spacing: -1.5px;

  color: #284b54;
  @media only screen and (max-width: 981px) and (min-width: 781px) {
    width: 100%;
    padding: 10px;
    text-align: center;
    display: flex;
  }
  @media (max-width: 780px) {
    font-size: 18px;
    line-height: 16px;
    width: 100%;
    margin: 5px;
    text-align: center;
    display: block;
  }
`;
export const ContactCenterItem = styled.div`
  width: 30%;
  position: relative;
`;
export const ContactCenterItemIconWrapper = styled.div`
  width: 100%;
`;
export const ContactCenterItemImage = styled.img`
  ${(props) => props.tr && `transform: matrix(0.33, 0.93, -0.97, 0.29, 0, 0);`}
`;

export const ContactRightItem = styled.div`
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: 981px) and (min-width: 781px) {
    width: 80%;
  }
  @media (max-width: 780px) {
    width: 170px;
  }
`;
export const ContactUsButton = styled.span`
  width: 80%;
  background: #284b54;
  border-radius: 10.3823px;
  padding: 15px 10px;
  cursor: pointer;
  border: none;
  text-decoration: none;
  text-align: center;
  @media only screen and (max-width: 981px) and (min-width: 781px) {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  @media (max-width: 780px) {
    width: 100%;
    text-align: center;
  }
`;
export const ContactButtonTitle = styled.span`
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  text-align: center;
  padding: 0px 30px;
  color: #ffffff;
  @media (max-width: 780px) {
    font-size: 16px;
    line-height: 16px;
    width: 100%;
  }
`;
export const FooterBorder = styled.span`
  position: absolute;
  border-bottom: 1px solid #ffffff;
  width: 80%;
  bottom: 25%;
  /* right: 1; */
  left: 9%;
  @media only screen and (max-width: 981px) and (min-width: 781px) {
    position: relative;
    width: 100%;
    left: 0%;
  }
  @media (max-width: 780px) {
    position: relative;
    width: 100%;
    left: 0%;
  }
`;

export const FooterBottomWrapper = styled.div`
  position: absolute;
  bottom: 17%;
  left: 9%;
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media only screen and (max-width: 981px) and (min-width: 781px) {
    position: relative;
    bottom: 0%;
    left: 0%;
    flex-direction: column;
    text-align: center;
    margin: 20px 0px 50px 0px;
  }
  @media (max-width: 780px) {
    position: relative;
    bottom: 0%;
    left: 0%;
    flex-direction: column;
    text-align: center;
    margin: 20px 0px 50px 0px;
  }
`;
export const FooterBottomLeft = styled.div`
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 23px;

  color: #ffffff;
`;
export const FooterBottomRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
`;
export const FooterBottomRightIcon = styled.div`
  background-color: #284b54;
  margin-right: 15px;
  border-radius: 50%;
  ${(props) =>
    props.fb
      ? `
     padding: 15px 22px;

  `
      : `  padding: 15px 20px;

  `}
`;
export const FooterSocialIcon = styled.img`
  ${(props) =>
    props.fb
      ? `  width: 13px;
`
      : `  width: 25px;
`}
`;
export const FooterSvg = styled.div`
  position: absolute;
  bottom: 0;
  ${(props) =>
    props.top
      ? `left:63%;
     bottom:8%
  `
      : `left:50%`}
`;
export const SvgImage = styled.img``;
