import React, { useState } from "react";
import {
  ContactButtonTitle,
  ContactCenterItem,
  ContactCenterItemIconWrapper,
  ContactCenterItemImage,
  ContactLeftItem,
  ContactRightItem,
  ContactUsButton,
  ContactUsContainer,
  ContactUsWrapper,
  FooterBorder,
  FooterBottomLeft,
  FooterBottomRight,
  FooterBottomRightIcon,
  FooterBottomWrapper,
  FooterContainer,
  FooterIcon,
  FooterLeftIconContainer,
  FooterLeftTitle,
  FooterRightItem,
  FooterRightItemItemName,
  FooterRightItemTitle,
  FooterRightItmeTitle,
  FooterSocialIcon,
  FooterSvg,
  FooterWrapper,
  FooterWrapperLeft,
  FooterWrapperRight,
  SvgIcon,
  SvgImage,
  FooterContainerMobile,
  ContactUsContainerMobile,
  FlexBoxWrap,
} from "./footer-style";
import logo from "../../images/logo.png";
import { FlexBox } from "../Header/header-style";
import ModalSignup2 from "../modal/modal2";

export default function Footer() {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisible2, setIsModalVisible2] = useState(false);

  return (
    <>
      <FooterContainer>
        <ContactUsContainer>
          <ContactUsWrapper>
            <ContactLeftItem>
              {/* Have any questions, <br /> feel free to ask! */}
              Subscribe to our newsletter
            </ContactLeftItem>
            <ContactCenterItem>
              <ContactCenterItemIconWrapper>
                <ContactCenterItemImage
                  src={require("../../images/Group 48.svg").default}
                  alt="svg"
                  style={{ width: "90%" }}
                />
              </ContactCenterItemIconWrapper>
            </ContactCenterItem>
            <ContactRightItem>
              <ContactUsButton
                onClick={() => setIsModalVisible2(true)}
                // href="https://www.playshotcaller.com/contact"
                // target="_blank"
              >
                <ContactButtonTitle>Sign Up</ContactButtonTitle>
              </ContactUsButton>
            </ContactRightItem>
          </ContactUsWrapper>
        </ContactUsContainer>
        <FooterWrapper>
          <FooterWrapperLeft>
            {/* <SvgIcon src={require("../../images/Ellipse 1.svg").default} />
          <SvgIcon src={require("../../images/Ellipse 1.svg").default} /> */}
            <FooterLeftTitle>
              Our mission is simple, "Bringing communities <br /> closer
              together through the power of sports & <br /> technology."
            </FooterLeftTitle>
            <FooterLeftIconContainer>
              <FooterIcon
                src={require("../../images/finger.white 1.svg").default}
                alt="image"
              />
            </FooterLeftIconContainer>
          </FooterWrapperLeft>
          <FooterWrapperRight>
            <FooterRightItem>
              <FooterRightItemTitle>Company</FooterRightItemTitle>
              <a
                href="https://medium.com/@ShotCaller"
                target="_blank"
                rol="noreferrer"
              >
                <FooterRightItemItemName>Blog</FooterRightItemItemName>
              </a>
              <a
                href="https://www.playshotcaller.com/about"
                target="_blank"
                rol="noreferrer"
              >
                <FooterRightItemItemName>About</FooterRightItemItemName>
              </a>
              <a
                href="https://www.playshotcaller.com/contact"
                target="_blank"
                rol="noreferrer"
              >
                <FooterRightItemItemName>Careers</FooterRightItemItemName>
              </a>
              <a
                href="https://www.playshotcaller.com/contact"
                target="_blank"
                rol="noreferrer"
              >
                <FooterRightItemItemName>Partnerships</FooterRightItemItemName>
              </a>
              <a
                href="https://www.playshotcaller.com/contact"
                target="_blank"
                rol="noreferrer"
              >
                <FooterRightItemItemName>Support Us</FooterRightItemItemName>
              </a>
              <a
                href="https://www.playshotcaller.com/contact"
                target="_blank"
                rol="noreferrer"
              >
                <FooterRightItemItemName>Contact Us</FooterRightItemItemName>
              </a>
            </FooterRightItem>

            <FooterRightItem>
              <FooterRightItemTitle>Partners</FooterRightItemTitle>

              <a
                href="https://www.endaomints.xyz/"
                target="_blank"
                rol="noreferrer"
              >
                <FooterRightItemItemName>MNFT</FooterRightItemItemName>
              </a>
              <a href="https://aeterna.media/" target="_blank" rol="noreferrer">
                <FooterRightItemItemName>Aeterna Media</FooterRightItemItemName>
              </a>
              <a
                href="https://smartnorth.org/projects/"
                target="_blank"
                rol="noreferrer"
              >
                <FooterRightItemItemName>Smart North</FooterRightItemItemName>
              </a>
              <a
                href="https://www.instagram.com/thesneakerplugmn/?hl=en"
                target="_blank"
                rol="noreferrer"
              >
                <FooterRightItemItemName>
                  The Sneaker Plug{" "}
                </FooterRightItemItemName>
              </a>
              <a
                href="https://www.facebook.com/One-of-them-Ones-105251231194878/"
                target="_blank"
                rol="noreferrer"
              >
                <FooterRightItemItemName>
                  One of Them Ones
                </FooterRightItemItemName>
              </a>
              <a
                href="https://www.instagram.com/steff_weezy/"
                target="_blank"
                rol="noreferrer"
              >
                <FooterRightItemItemName>Steff Weezy</FooterRightItemItemName>
              </a>
              <a
                href="https://loveyoucookie.com/"
                target="_blank"
                rol="noreferrer"
              >
                <FooterRightItemItemName>
                  Love You Cookie
                </FooterRightItemItemName>
              </a>
              <a href="https://flow.com/" target="_blank" rol="noreferrer">
                <FooterRightItemItemName>Flow</FooterRightItemItemName>
              </a>
            </FooterRightItem>

            <FooterRightItem>
              <FooterRightItemTitle>Policy</FooterRightItemTitle>
              <a
                href="https://www.playshotcaller.com/privacy-policy"
                target="_blank"
                rol="noreferrer"
              >
                <FooterRightItemItemName>
                  Privacy Policy
                </FooterRightItemItemName>
              </a>
              <a
                href="https://www.playshotcaller.com/terms-of-service"
                target="_blank"
                rol="noreferrer"
              >
                <FooterRightItemItemName>
                  Terms of Service
                </FooterRightItemItemName>
              </a>
            </FooterRightItem>
          </FooterWrapperRight>
        </FooterWrapper>
        <FooterBorder></FooterBorder>
        <FooterBottomWrapper>
          <FooterBottomLeft>
            2022 - © Social BEAST Enterprises, LLC
          </FooterBottomLeft>
          <FooterBottomRight>
            <a href="https://twitter.com/PlayShotCaller" target="_blank">
              <FooterBottomRightIcon>
                <FooterSocialIcon
                  src={require("../../images/twitter.svg").default}
                  alt="twitter"
                />
              </FooterBottomRightIcon>
            </a>
            <a href="https://www.instagram.com/playshotcaller/" target="_blank">
              <FooterBottomRightIcon>
                <FooterSocialIcon
                  src={require("../../images/insta.svg").default}
                />
              </FooterBottomRightIcon>
            </a>
            <a
              href="https://www.linkedin.com/company/shotcaller"
              target="_blank"
            >
              <FooterBottomRightIcon>
                <FooterSocialIcon
                  src={require("../../images/Linkedin.svg").default}
                />
              </FooterBottomRightIcon>
            </a>
            <a href="https://www.facebook.com/playShotCaller/" target="_blank">
              <FooterBottomRightIcon fb>
                <FooterSocialIcon
                  fb
                  src={require("../../images/fb.svg").default}
                />
              </FooterBottomRightIcon>
            </a>
          </FooterBottomRight>
        </FooterBottomWrapper>
        <FooterSvg>
          <SvgImage src={require("../../images/Groupbottom.svg").default} />
        </FooterSvg>
        <FooterSvg top>
          <SvgImage src={require("../../images/Grouptop.svg").default} />
        </FooterSvg>
      </FooterContainer>

      {/* mobile ui */}
      <FooterContainerMobile>
        <ContactUsContainerMobile>
          <ContactLeftItem style={{ marginBottom: "20px" }}>
            {/* Have any questions, feel free to ask! */}
            Subscribe to our newsletter
          </ContactLeftItem>
          <ContactCenterItemImage
            src={require("../../images/Group 48.svg").default}
            alt="svg"
            style={{ width: "50%", marginBottom: "25px" }}
          />
          <ContactRightItem>
            <ContactUsButton
              onClick={() => setIsModalVisible2(true)}
              // href="https://www.playshotcaller.com/contact"
              // target="_blank"
            >
              <ContactButtonTitle>Sign Up</ContactButtonTitle>
            </ContactUsButton>
          </ContactRightItem>
        </ContactUsContainerMobile>
        <FooterLeftTitle>
          Our mission is simple, "Bringing communities closer together through
          the power of sports & technology."
        </FooterLeftTitle>
        <FlexBoxWrap>
          <FooterRightItem>
            <FooterRightItemTitle>Company</FooterRightItemTitle>
            <a
              href="https://medium.com/@ShotCaller"
              target="_blank"
              rol="noreferrer"
            >
              <FooterRightItemItemName>Blog</FooterRightItemItemName>
            </a>
            <a
              href="https://www.playshotcaller.com/about"
              target="_blank"
              rol="noreferrer"
            >
              <FooterRightItemItemName>About</FooterRightItemItemName>
            </a>
            <a
              href="https://www.playshotcaller.com/contact"
              target="_blank"
              rol="noreferrer"
            >
              <FooterRightItemItemName>Careers</FooterRightItemItemName>
            </a>
            <a
              href="https://www.playshotcaller.com/contact"
              target="_blank"
              rol="noreferrer"
            >
              <FooterRightItemItemName>Partnerships</FooterRightItemItemName>
            </a>
            <a
              href="https://www.playshotcaller.com/contact"
              target="_blank"
              rol="noreferrer"
            >
              <FooterRightItemItemName>Support Us</FooterRightItemItemName>
            </a>
            <a
              href="https://www.playshotcaller.com/contact"
              target="_blank"
              rol="noreferrer"
            >
              <FooterRightItemItemName>Contact Us</FooterRightItemItemName>
            </a>
          </FooterRightItem>
          <FooterRightItem>
            <FooterRightItemTitle>Partners</FooterRightItemTitle>

            <a
              href="https://www.endaomints.xyz/"
              target="_blank"
              rol="noreferrer"
            >
              <FooterRightItemItemName>MNFT</FooterRightItemItemName>
            </a>
            <a href="https://aeterna.media/" target="_blank" rol="noreferrer">
              <FooterRightItemItemName>Aeterna Media</FooterRightItemItemName>
            </a>
            <a
              href="https://smartnorth.org/projects/"
              target="_blank"
              rol="noreferrer"
            >
              <FooterRightItemItemName>Smart North</FooterRightItemItemName>
            </a>
            <a
              href="https://www.instagram.com/thesneakerplugmn/?hl=en"
              target="_blank"
              rol="noreferrer"
            >
              <FooterRightItemItemName>
                The Sneaker Plug{" "}
              </FooterRightItemItemName>
            </a>
            <a
              href="https://www.facebook.com/One-of-them-Ones-105251231194878/"
              target="_blank"
              rol="noreferrer"
            >
              <FooterRightItemItemName>
                One of Them Ones
              </FooterRightItemItemName>
            </a>
            <a
              href="https://www.instagram.com/steff_weezy/"
              target="_blank"
              rol="noreferrer"
            >
              <FooterRightItemItemName>Steff Weezy</FooterRightItemItemName>
            </a>
            <a
              href="https://loveyoucookie.com/"
              target="_blank"
              rol="noreferrer"
            >
              <FooterRightItemItemName>Love You Cookie</FooterRightItemItemName>
            </a>
            <a href="https://flow.com/" target="_blank" rol="noreferrer">
              <FooterRightItemItemName>Flow</FooterRightItemItemName>
            </a>
          </FooterRightItem>
          <FooterRightItem>
            <FooterRightItemTitle>Policy</FooterRightItemTitle>
            <a
              href="https://www.playshotcaller.com/privacy-policy"
              target="_blank"
              rol="noreferrer"
            >
              <FooterRightItemItemName>Privacy Policy</FooterRightItemItemName>
            </a>
            <a
              href="https://www.playshotcaller.com/terms-of-service"
              target="_blank"
              rol="noreferrer"
            >
              <FooterRightItemItemName>
                Terms of Service
              </FooterRightItemItemName>
            </a>
          </FooterRightItem>
        </FlexBoxWrap>
        <FooterIcon
          src={require("../../images/finger.white 1.svg").default}
          alt="image"
        />
        <FooterBorder></FooterBorder>
        <FooterBottomWrapper>
          <FooterBottomLeft style={{ margin: "20px 0 20px 0" }}>
            2022 - © Social BEAST Enterprises, LLC
          </FooterBottomLeft>
          <FooterBottomRight style={{ margin: "0px 0 0px 10px" }}>
            <a href="https://twitter.com/PlayShotCaller" target="_blank">
              <FooterBottomRightIcon>
                <FooterSocialIcon
                  src={require("../../images/twitter.svg").default}
                  alt="twitter"
                />
              </FooterBottomRightIcon>
            </a>
            <a href="https://www.instagram.com/playshotcaller/" target="_blank">
              <FooterBottomRightIcon>
                <FooterSocialIcon
                  src={require("../../images/insta.svg").default}
                />
              </FooterBottomRightIcon>
            </a>
            <a
              href="https://www.linkedin.com/company/shotcaller"
              target="_blank"
            >
              <FooterBottomRightIcon>
                <FooterSocialIcon
                  src={require("../../images/Linkedin.svg").default}
                />
              </FooterBottomRightIcon>
            </a>
            <a href="https://www.facebook.com/playShotCaller/" target="_blank">
              <FooterBottomRightIcon fb>
                <FooterSocialIcon
                  fb
                  src={require("../../images/fb.svg").default}
                />
              </FooterBottomRightIcon>
            </a>
          </FooterBottomRight>
          <div style={{ marginTop: "50px" }}>
            <SvgImage src={require("../../images/Grouptop.svg").default} />
            <SvgImage src={require("../../images/Groupbottom.svg").default} />
          </div>
        </FooterBottomWrapper>
      </FooterContainerMobile>
      <ModalSignup2
        setIsModalVisible={setIsModalVisible2}
        isModalVisible={isModalVisible2}
        // title={"Get MSP NFT Project Updates"}
        title={"Subscribe to our newsletter"}
        buttontitle={"Subscribe"}
      />
    </>
  );
}
