import styled from "styled-components";
import bg from "../../../images/bgcheckout.png";
import bottomImage from "../../../images/Rectangle 23936.png";

export const HeroContainer = styled.div`
  margin: 40px 40px 40px 150px;
  height: 750px;
  background: url(${bg});
  background-repeat: no-repeat;
  background-size: 100% 100%;
  @media only screen and (max-width: 1240px) and (min-width: 780px) {
    margin: 20px 20px 20px 100px;
    height: 560px;
  }
  @media only screen and (max-width: 780px) and (min-width: 320px) {
    height: 100%;
    margin: 20px;
  }
`;

export const HeroRedImageContainer = styled.div`
  margin-left: -110px;
  position: relative;
  @media only screen and (max-width: 1240px) and (min-width: 780px) {
    margin-left: -80px;
  }
  @media only screen and (max-width: 780px) and (min-width: 320px) {
    margin-left: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const LeftText = styled.h5`
  font-family: "Manrope";
  font-style: normal;
  font-weight: 800;
  font-size: 25px;
  line-height: 34px;
  color: #000000;
  margin-left: 5%;
  width: 100%;
  margin-top: 2%;
  @media only screen and (max-width: 1240px) and (min-width: 780px) {
    font-size: 16px;
    line-height: 20px;
    width: 100%;
  }
  @media only screen and (max-width: 780px) and (min-width: 320px) {
    font-size: 11px;
    line-height: 16px;
    width: 100%;
    margin: 10px 0px 10px 20px;
  }
`;

export const BottomButton = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10%;
  margin-left: 5%;
`;
export const PurchaseButton = styled.div`
  background: #bbe600;
  width: 245px;
  height: 66px;
  box-shadow: 0px 10px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  @media only screen and (max-width: 1240px) and (min-width: 780px) {
    width: 145px;
    height: 40px;
  }
  @media only screen and (max-width: 780px) and (min-width: 320px) {
    width: 100px;
    height: 30px;
    box-shadow: 0px 5px 2px rgba(0, 0, 0, 0.25);
  }
`;
export const Buttontext = styled.h4`
  margin-top: 14px;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 39px;
  color: #485470;
  @media only screen and (max-width: 1240px) and (min-width: 780px) {
    font-size: 16px;
    line-height: 20px;
  }
  @media only screen and (max-width: 780px) and (min-width: 320px) {
    font-size: 14px;
    line-height: 14px;
    margin-top: 8px;
  }
`;
export const CancelButton = styled.button`
  margin-left: 30px;
  width: 245px;
  height: 66px;
  background: #f3ffb6;
  border: 1px solid #000000;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  @media only screen and (max-width: 1240px) and (min-width: 780px) {
    width: 145px;
    height: 45px;
  }
  @media only screen and (max-width: 780px) and (min-width: 320px) {
    width: 100px;
    height: 32px;
  }
`;
export const CancelButtontext = styled.h4`
  margin-top: 10px;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 33px;
  color: #485470;
  @media only screen and (max-width: 1240px) and (min-width: 780px) {
    font-size: 16px;
    line-height: 20px;
  }
  @media only screen and (max-width: 780px) and (min-width: 320px) {
    font-size: 14px;
    line-height: 14px;
    margin-top: 8px;
  }
`;

export const HeroRedImage = styled.img`
  width: 95%;
  @media only screen and (max-width: 780px) and (min-width: 320px) {
    width: 100%;
    min-height: 50px;
    margin-right: 20px;
  }
`;

export const HeroTitleText = styled.h1`
  position: absolute;
  bottom: 30px;
  left: 30px;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 800;
  font-size: 40px;
  line-height: 55px;
  text-align: center;
  color: #1c2541;
  @media only screen and (max-width: 1240px) and (min-width: 780px) {
    font-size: 24px;
    line-height: 30px;
  }
  @media only screen and (max-width: 780px) and (min-width: 320px) {
    font-size: 16px;
    line-height: 20px;
    margin-left: 0%;
    bottom: 10px;
    left: 16px;
  }
`;

export const TopSubTextSection = styled.span`
  position: absolute;
  bottom: 10px;
  left: 40px;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 34px;
  color: #000000;
  @media only screen and (max-width: 1240px) and (min-width: 780px) {
    font-size: 16px;
    line-height: 20px;
  }
  @media only screen and (max-width: 780px) and (min-width: 320px) {
    font-size: 16px;
    line-height: 16px;
    margin-left: 0%;
    bottom: 5px;
    left: 16px;
  }
`;

export const SpanText = styled.span`
  /* color: #e20000; */
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 34px;
  @media only screen and (max-width: 1240px) and (min-width: 780px) {
    font-size: 16px;
    line-height: 20px;
  }
  @media only screen and (max-width: 780px) and (min-width: 320px) {
    font-size: 16px;
    line-height: 16px;
    margin-left: 0%;
  }
`;

export const CardSection = styled.div`
  margin-right: 50px;
  margin-top: -80px;
  position: relative;
  @media only screen and (max-width: 1240px) and (min-width: 780px) {
    margin-top: -50px;
  }
  @media only screen and (max-width: 780px) and (min-width: 320px) {
    margin-top: 0px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-left: 20px;
  }
`;

export const ShadowCardImage = styled.img`
  @media only screen and (max-width: 1240px) and (min-width: 780px) {
    width: 300px;
  }
`;

export const CardImage = styled.div`
  width: 353px;
  height: 480px;
  background-color: white;
  border: 1px solid black;
  box-shadow: 5px 5px 15px 0px grey;
  transform: rotate(10.46deg);
  display: flex;
  flex-direction: column;
  margin: 20px 30px 0px 0px;
  @media only screen and (max-width: 1240px) and (min-width: 780px) {
    width: 223px;
    height: 330px;
    margin: 20px 0px 0px 0px;
  }
  @media only screen and (max-width: 780px) and (min-width: 320px) {
    width: 190px;
    height: 300px;
    margin: 20px 0px 0px 0px;
    position: absolute;
    transform: rotate(10deg);
    margin-top: 55px;
    margin-left: 15px;
  }
`;

export const CardImage2 = styled.div`
  width: 353px;
  height: 480px;
  background-color: white;
  border: 1px solid black;
  box-shadow: 5px 5px 15px 0px grey;
  transform: rotate(10.46deg);
  position: absolute;
  top: -45px;
  left: -20px;
  // z-index: -1;
  margin: 20px 30px 0px 0px;
  @media only screen and (max-width: 1240px) and (min-width: 780px) {
    width: 223px;
    height: 330px;
    margin: 20px 0px 0px 0px;
  }
  @media only screen and (max-width: 780px) and (min-width: 320px) {
    width: 190px;
    height: 300px;
    transform: rotate(10deg);
    margin-top: 40px;
    top: 0px;
    left: 0px;
    position: relative;
  }
`;

export const CartTitleTopSection = styled.div`
  width: 100%;
  display: flex;
  align-items: left;
  justify-content: space-between;
  @media only screen and (max-width: 780px) and (min-width: 320px) {
    display: block;
  }
`;

export const CardTitle = styled.h4`
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  color: #485470;
  @media only screen and (max-width: 780px) and (min-width: 320px) {
    font-size: 12px;
    line-height: 12px;
  }
`;

export const CardTop = styled.img`
  padding: 20px;
  @media only screen and (max-width: 1240px) and (min-width: 780px) {
    width: 223px;
  }
  @media only screen and (max-width: 780px) and (min-width: 320px) {
    width: 190px;
    padding: 10px;
  }
`;
export const CardBottom = styled.div`
  width: 100%;
  padding: 20px 20px 10px 20px;
  @media only screen and (max-width: 1240px) and (min-width: 780px) {
    padding: 5px;
  }
  @media only screen and (max-width: 780px) and (min-width: 320px) {
    padding: 5px 10px;
  }
`;

export const CardPrice = styled.span`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #99a7c7;
  @media only screen and (max-width: 1240px) and (min-width: 780px) {
    font-size: 10px;
    line-height: 10px;
  }
  @media only screen and (max-width: 780px) and (min-width: 320px) {
    font-size: 10px;
    line-height: 10px;
  }
`;

export const CartTitleBottomSection = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0px 20px 0px 20px;
  @media only screen and (max-width: 1240px) and (min-width: 780px) {
    padding: 5px;
  }
  @media only screen and (max-width: 780px) and (min-width: 320px) {
    padding: 5px 10px;
  }
`;

export const OwnerName = styled.div`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: #99a7c7;
  display: flex;
  flex: 1;
  @media only screen and (max-width: 1240px) and (min-width: 780px) {
    font-size: 12px;
    line-height: 12px;
  }
  @media only screen and (max-width: 780px) and (min-width: 320px) {
    font-size: 12px;
    line-height: 12px;
  }
`;
export const Rank = styled.span`
  font-family: "Space Grotesk";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  /* line-height: 150px; */
  /* or 375% */
  color: #485470;
  margin-right: 30px;
  text-transform: uppercase;
  @media only screen and (max-width: 1240px) and (min-width: 780px) {
    font-size: 20px;
    line-height: 10px;
  }
  @media only screen and (max-width: 780px) and (min-width: 320px) {
    font-size: 16px;
    line-height: 12px;
  }
`;
export const QrCodeImage = styled.img`
  transform: rotate(-9deg);
  @media only screen and (max-width: 1240px) and (min-width: 780px) {
    width: 20%;
  }
  @media only screen and (max-width: 780px) and (min-width: 320px) {
    width: 20%;
  }
`;

export const JustifyBetween = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  @media only screen and (max-width: 780px) and (min-width: 320px) {
    flex-direction: column;
  }
`;

export const ItemCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media only screen and (max-width: 780px) and (min-width: 320px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const BottomImageConatiner = styled.div`
  background: url(${bottomImage});
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 185px;
  width: 58%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  @media only screen and (max-width: 1240px) and (min-width: 1025px) {
    width: 59%;
    height: 150px;
    // margin-top: 20px;
  }
  @media only screen and (max-width: 1024px) and (min-width: 780px) {
    width: 59%;
    height: 150px;
    margin-top: 30px;
  }
  @media only screen and (max-width: 780px) and (min-width: 320px) {
    display: none;
  }
`;

export const BottomImageConatinerMobile = styled.div`
  background: url(${bottomImage});
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 185px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  margin-top: 10px;
  @media only screen and (min-width: 780px) and (min-width: 320px) {
    height: 160px;
    padding: 10px 20px 10px 10px;
    margin: 20px;
  }
`;

export const PriceLeft = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const PriceLeftTop = styled.h3`
  font-family: "Manrope";
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 24px;
  text-align: center;
  color: #1c2541;
  @media only screen and (max-width: 1240px) and (min-width: 780px) {
    font-size: 16px;
    line-height: 16px;
  }
  @media only screen and (max-width: 780px) and (min-width: 320px) {
    font-size: 16px;
    line-height: 16px;
    margin-left: 10px;
  }
`;
export const PriceLeftBottom = styled.span`
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  text-transform: uppercase;
  color: #7d8cac;
  @media only screen and (max-width: 1240px) and (min-width: 780px) {
    font-size: 12px;
    line-height: 12px;
  }
  @media only screen and (max-width: 780px) and (min-width: 320px) {
    font-size: 12px;
    line-height: 12px;
    margin-left: 10px;
  }
`;

export const PriceRight = styled.div`
  width: 80%;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 24px;
  padding: 20px 65px 20px 20px;
  color: #1c2541;
  @media only screen and (max-width: 1240px) and (min-width: 780px) {
    font-size: 16px;
    line-height: 16px;
  }
  @media only screen and (max-width: 780px) and (min-width: 320px) {
    font-size: 14px;
    line-height: 14px;
    width: 100%;
    margin-top: 10px;
    padding: 20px 10px;
    width: 90%;
  }
`;

export const Whytext = styled.span`
  margin-left: 20px;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 27px;
  text-align: center;
  cursor: pointer;
  color: #7d8cac;
  @media only screen and (max-width: 1240px) and (min-width: 780px) {
    font-size: 12px;
    line-height: 12px;
  }
  @media only screen and (max-width: 780px) and (min-width: 320px) {
    font-size: 12px;
    line-height: 12px;
  }
`;

export const DisplayOnlyMobile = styled.div`
  display: none;
  padding: 30px 20px 20px 20px;
  @media (max-width: 780px) {
    display: block;
  }
`;
