import styled from "styled-components";

export const ModalContainer = styled.div`
  width: 100%;
  height: 74vh;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  @media only screen and (max-width: 426px) and (min-width: 320px) {
    width: 100%;
    height: 90vh;
  }
`;
export const InnerTopContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const InnerCenterContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
export const Signup = styled.span`
  margin-top: 20px;
  font-family: "Inter";
  font-style: normal;
  font-weight: bolder;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #007aff;
`;
export const InnerBottomContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
`;
export const BottomWrapper = styled.div`
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @media only screen and (max-width: 781px) and (min-width: 377px) {
    width: 65%;
    line-height: 29px;
    margin-bottom: 10px;
  }
  @media only screen and (max-width: 376px) and (min-width: 320px) {
    width: 90%;
    line-height: 29px;
    margin-bottom: 10px;
  }
`;
export const Detail = styled.span`
  width: 60%;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #545454;
  @media only screen and (max-width: 781px) and (min-width: 320px) {
    width: 100%;
    line-height: 29px;
    margin-bottom: 10px;
  }
  @media only screen and (max-width: 376px) and (min-width: 320px) {
    width: 100%;
    line-height: 29px;
    font-size: 13px;
    /* margin-bottom: 10px; */
  }
`;
export const Easy = styled.span`
  width: 50%;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: #545454;
  margin-bottom: 25px;
  @media only screen and (max-width: 781px) and (min-width: 376px) {
    width: 100%;
  }
  @media only screen and (max-width: 375px) and (min-width: 320px) {
    width: 100%;
    margin-bottom: 0px;
  }
`;
export const ButtonDiv = styled.button`
  padding: 8px 40px;
  background-color: #fc2b5a;
  border: none;
  margin-bottom: 20px;
  color: white;
  cursor: pointer;
  width: 100%;
  border-radius: 20px;
  @media only screen and (max-width: 781px) and (min-width: 376px) {
    padding: 8px 20px;
    /* width: 80%; */
  }
  @media only screen and (max-width: 375px) and (min-width: 320px) {
    padding: 8px 20px;
    width: 100%;
  }
`;
export const Btntext = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  text-align: center;

  color: #ffffff;
  @media only screen and (max-width: 375px) and (min-width: 320px) {
    font-size: 13px;
  }
`;
export const Image = styled.img`
  width: 80%;
  height: 100%;
`;
export const Link = styled.a`
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
  text-align: center;
  color: #545454;
  text-decoration: none;
`;
// export const modalContainer=styled.div``
// export const modalContainer=styled.div``
// export const modalContainer=styled.div``
// export const modalContainer=styled.div``
// export const modalContainer=styled.div``
// export const modalContainer=styled.div``
// export const modalContainer=styled.div``
// export const modalContainer=styled.div``
// export const modalContainer=styled.div``
// export const modalContainer=styled.div``
// export const modalContainer=styled.div``
// export const modalContainer=styled.div``
// export const modalContainer=styled.div``
// export const modalContainer=styled.div``
// export const modalContainer=styled.div``
// export const modalContainer=styled.div``
// export const modalContainer=styled.div``

// .title{
//     font-family: 'Inter';
// font-style: normal;
// font-weight: 600;
// font-size: 16px;
// line-height: 19px;
// text-align: center;

// color: #282525;
// }
