import styled from "styled-components";
import bgLines from "../../images/aboutBgLines.png";

export const AboutContainer = styled.div`
  position: relative;
  background-color: #fc2b5a;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  @media only screen and (max-width: 780px) and (min-width: 320px) {
    flex-direction: column;
    padding: 20px;
  }
  @media only screen and (max-width: 1445px) and (min-width: 1330px) {
    width: 100%;
  }
  @media only screen and (max-width: 1329px) and (min-width: 781px) {
    flex-direction: column;
    padding: 20px;
    width: 100%;
  }
`;

export const AboutLeftContainer = styled.div`
  width: 55%;
  height: 100%;
  display: flex;
  justify-content: start;
  padding: 5rem 5rem 5rem 8rem;
  flex-direction: column;
  background: url(${bgLines});
  background-position: center left;
  background-repeat: no-repeat;
  @media only screen and (max-width: 1329px) and (min-width: 600px) {
    background: url();
    background-size: cover;
    background-color: #fc2b5a;
    padding: 20px;
    width: 90%;
  }
  @media only screen and (max-width: 599px) and (min-width: 320px) {
    background: url();
    background-color: #fc2b5a;
    padding: 20px;
    width: 100%;
  }
`;
export const AboutHeader = styled.h1`
  font-family: "Manrope";
  font-style: normal;
  font-weight: 800;
  font-size: 64px;
  line-height: 87px;
  color: #ffffff;
  @media only screen and (max-width: 1329px) and (min-width: 600px) {
    font-size: 30px;
    line-height: 35px;
    margin-top: 20px;
  }
  @media only screen and (max-width: 599px) and (min-width: 320px) {
    font-size: 30px;
    line-height: 35px;
    margin-top: 0px;
  }
`;
export const AboutDescription = styled.p`
  width: 100%;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 40px;

  color: #ffffff;
  margin-top: 30px;
  @media only screen and (max-width: 1329px) and (min-width: 320px) {
    font-size: 20px;
    line-height: 25px;
  }
`;
export const AboutButton = styled.button`
  margin-top: 90px;
  width: 250px;
  background: #36f599;
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 23.1397px;
  padding: 11px 26px;
  cursor: pointer;
  @media only screen and (max-width: 1329px) and (min-width: 320px) {
    width: 200px;
    margin-top: 20px;
  }
`;
export const ButtonTitle = styled.span`
  font-family: "Manrope";
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 34px;
  color: #1c2541;
  @media only screen and (max-width: 1329px) and (min-width: 320px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

export const ImageContainerMobile = styled.div`
  position: relative;
  padding: 0px 128px 0px 0px;
  display: none;
  @media only screen and (max-width: 1429px) and (min-width: 1330px) {
    padding: 0px 50px 0px 0px;
  }
  @media only screen and (max-width: 1329px) and (min-width: 320px) {
    padding: 0 0px 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const ImageContainer = styled.div`
  position: relative;
  padding: 0px 128px 0px 0px;
  @media only screen and (max-width: 1429px) and (min-width: 1330px) {
    padding: 0px 50px 0px 0px;
  }
  @media only screen and (max-width: 1329px) and (min-width: 320px) {
    padding: 0px;
    display: none;
  }
`;
export const MainImage = styled.img`
  @media only screen and (max-width: 1329px) and (min-width: 600px) {
    width: 500px;
  }
  @media only screen and (max-width: 1329px) and (min-width: 600px) {
    width: 350px;
  }
  @media only screen and (max-width: 599px) and (min-width: 320px) {
    width: 250px;
  }
`;
