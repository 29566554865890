import React, { useState } from "react";
import {
  AboutButton,
  AboutContainer,
  AboutDescription,
  AboutHeader,
  AboutLeftContainer,
  ButtonTitle,
  ImageContainer,
  MainImage,
  ImageContainerMobile,
} from "./about-style";
import AllImages from "../../images/Component 4.png";
import LogoWhite from "../../images/ShotCaller.name.logo 1.png";
import { TitleImage } from "../Header/header-style";
import ModalSignup from "../modal";
import { Link } from "react-router-dom";

export default function About() {
  const [isModalVisible, setIsModalVisible] = useState(false);

  return (
    <AboutContainer id="discover">
      <ModalSignup
        setIsModalVisible={setIsModalVisible}
        isModalVisible={isModalVisible}
      />
      <AboutLeftContainer>
        <AboutHeader>
          What is
          <br />
          <TitleImage bigger src={LogoWhite} /> ?
        </AboutHeader>
        <AboutDescription>
          ShotCaller is a free-2-play gaming app where fans make sports picks &
          win rewards from local partners for playing along.
          <br /> <br /> This September we'll be launching our inaugural NFT
          reward collection highlighting black-owned community partners. <br />
          <br /> Make history by purchasing a NFT reward today or winning one in
          an upcoming contest!
        </AboutDescription>
        <ImageContainerMobile>
          <MainImage src={AllImages} alt="phone image" />
        </ImageContainerMobile>
        <Link to={"/checkout"}>
          <AboutButton>
            <ButtonTitle>Get yours now</ButtonTitle>
          </AboutButton>
        </Link>
      </AboutLeftContainer>
      <ImageContainer>
        <MainImage src={AllImages} alt="phone image" />
      </ImageContainer>
    </AboutContainer>
  );
}
