import { Modal } from "antd";
import React from "react";
import TickImages from "../../images/success2.gif";
import "./payment.css";
export default function PaymentSuccessModal({
  isModalVisible,
  setIsModalVisible,
}) {
  return (
    <div>
      <Modal
        width={500}
        // height={800}
        // title="Basic Modal"

        footer={null}
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        bodyStyle={{
          width: "100%",
          height: "300px",
          padding: "40px",
          background: "rgba(255, 255, 255, 0.95)",
          //   background: "rgb(44 174 133 / 95%)",

          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {" "}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <h1
            style={{
              fontSize: "24px",
              fontFamily: "Inter",
              fontWeight: "700",
              color: "GrayText",
              marginTop: "20px",
            }}
          >
            Transaction Successful!
          </h1>
          <img
            src={TickImages}
            alt="loading...."
            style={{ width: "300px", height: "200px" }}
          />

          <p
            style={{
              color: "#fc2b5a",
              fontSize: "16px",
              fontFamily: "Inter",
              fontWeight: "400",
            }}
          >
            Check email for NFT details
          </p>
        </div>
        {/*  */}
        {/* <> */}
        {/* <div id="success_tic" className="modal fade" role="dialog">
            <div className="modal-dialog">
              <div className="modal-content">
                <a className="close" href="#" data-dismiss="modal">
                  &times;
                </a>
                <div className="page-body">
                  <div className="head">
                    <h3 style={{ marginTop: "5px" }}>
                      Lorem ipsum dolor sit amet
                    </h3>
                  </div>

                  <h1 style={{ textAlign: "center" }}>
                    <div className="checkmark-circle">
                      <div className="background"></div>
                      <div className="checkmark draw"></div>
                    </div>
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </> */}
        {/*  */}
      </Modal>
    </div>
  );
}
