import styled from "styled-components";
import bgLines from "../../images/nftRewardBgLines.png";

export const Container = styled.div`
  padding-top: 65px;
  padding-bottom: 160px;
  background: url(${bgLines});
  background-repeat: no-repeat;
  @media (max-width: 481px) {
    width: 100%;
  }
  @media (max-width: 426px) {
    padding-bottom: 50px;
  }
  @media (max-width: 376px) {
    padding-bottom: 30px;
  }
  @media (max-width: 321px) {
    padding-bottom: 30px;
  }
`;
export const CardContainer = styled.div`
  width: 100%;
  overflow: hidden;
`;
export const Cards = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 135px;
  margin-left: 190px;
  /* @media (max-width: 1441px) {
    width: 100%;
  }
  @media (max-width: 1025px) {
    margin-left: 30px;
    width: 100%;
  } */
  @media only screen and (max-width: 1441px) and (min-width: 1026px) {
    width: 100%;
    margin-left: 0px;
  }
  @media only screen and (max-width: 981px) and (min-width: 769px) {
    width: 100%;
    margin-left: 0px;
    flex-direction: column;
  }

  @media only screen and (max-width: 768px) and (min-width: 481px) {
    width: 100%;
    margin-left: 0px;
    flex-direction: column;
    margin-bottom: 30px;
  }

  @media (max-width: 480px) {
    width: 100%;
    margin-left: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 30px;
  }
  @media (max-width: 426px) {
    width: 100%;
    margin-left: 0px;
    /* overflow: scroll; */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 30px;
  }

  @media (max-width: 376px) {
    width: 100%;
    margin-left: 0px;
  }
  @media (max-width: 321px) {
    width: 100%;
    margin-left: 0px;
  }
`;
export const Card = styled.div`
  background: #ffffff;
  border: 1.40722px solid #485470;
  border-radius: 8.44331px;
  padding: 19px 20px 22px 19px;
  width: 336px;
  height: 443px;
  margin: 0 15px;
  @media (max-width: 1441px) {
    width: 350px;
  }
  @media (max-width: 1025px) {
    width: 340px;
    margin-bottom: 30px;
  }

  @media (max-width: 780px) {
    width: 280px;
    padding: 0;
    height: 380px;
    margin-bottom: 30px;
  }
  @media (max-width: 481px) {
    width: 280px;
    padding: 0;
    height: 380px;
    margin-bottom: 30px;
  }
  @media (max-width: 480px) {
    width: 300px;
    margin-bottom: 30px;
  }
  @media (max-width: 426px) {
    width: 300px;
    margin-bottom: 30px;
  }
  @media (max-width: 376px) {
    width: 270px;
    margin-bottom: 30px;
  }
  @media (max-width: 321px) {
    width: 260px;
    margin-bottom: 30px;
  }
`;
export const CardImg = styled.img`
  height: 300px;
  @media (max-width: 780px) {
    width: 100%;
    height: 250px;
    padding: 10px;
  }
  @media (max-width: 480px) {
    width: 100%;
    height: 250px;
    padding: 10px;
  }
`;
export const CardTitleSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 18px;
  margin-bottom: 11px;
  @media only screen and (max-width: 768px) and (min-width: 201px) {
    padding: 10px;
  }
`;
export const CardTitle = styled.h1`
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  color: #485470;
  margin: 0;
  @media only screen and (max-width: 768px) and (min-width: 481px) {
    font-size: 16px;
  }
  @media (max-width: 426px) {
    font-size: 16px;
  }
  @media (max-width: 376px) {
    font-size: 16px;
  }
  @media (max-width: 321px) {
    font-size: 16px;
  }
`;
export const CardSold = styled.h6`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #99a7c7;
  margin: 0;
  @media (max-width: 426px) {
    font-size: 13px;
  }
  @media (max-width: 376px) {
    font-size: 10px;
  }
  @media (max-width: 321px) {
    font-size: 10px;
  }
`;
export const CardUser = styled.h1`
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: #485470;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 780px) {
    margin-left: 10px;
  }
  @media (max-width: 480px) {
    margin-left: 10px;
  }
`;

export const CardUserLeft = styled.div`
  display: flex;
  align-items: center;
`;
export const CardRight = styled.div`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: #99a7c7;
  margin: 0;
  @media (max-width: 481px) {
    font-size: 15px;
    margin-right: 5px;
    margin-top: -20px;
  }
  @media (max-width: 426px) {
    font-size: 13px;
    margin-right: 5px;
    margin-top: -20px;
  }
  @media (max-width: 376px) {
    font-size: 13px;
    margin-top: -20px;
    margin-right: 5px;
  }
  @media (max-width: 321px) {
    font-size: 10px;
    margin-top: -20px;
    margin-right: 5px;
  }
`;

export const CardUserStar = styled.img`
  margin-left: 3px;
`;
export const IntroSection = styled.div`
  padding: 0 30px 0 100px;
  position: relative;

  @media (max-width: 1441px) {
    width: 100%;
  }
  @media (max-width: 1025px) {
    width: 100%;
  }
  @media only screen and (max-width: 981px) and (min-width: 769px) {
    width: 100%;
    padding: 0 5px 0 35px;
  }
  @media only screen and (max-width: 768px) and (min-width: 481px) {
    width: 100%;
    padding: 0 5px 0 35px;
  }
  @media only screen and (max-width: 480px) and (min-width: 321px) {
    width: 100%;
    padding: 0 5px 0 35px;
  }
  @media (max-width: 426px) {
    width: 100%;
    padding: 0 5px 0 35px;
  }
  @media (max-width: 376px) {
    padding: 0 5px 0 35px;
    width: 100%;
  }
  @media (max-width: 321px) {
    padding: 0 5px 0 35px;
    width: 100%;
  }
`;
export const Heading = styled.h1`
  font-family: "Manrope";
  font-style: normal;
  font-weight: 800;
  font-size: 80px;
  line-height: 109px;
  color: #1c2541;
  margin: 0;
  @media only screen and (max-width: 981px) and (min-width: 769px) {
    font-size: 55px;
  }
  @media only screen and (max-width: 768px) and (min-width: 481px) {
    font-size: 55px;
  }
  @media only screen and (max-width: 480px) and (min-width: 20px) {
    font-size: 40px;
  }
`;

export const TitleBg = styled.span`
  position: absolute;
  width: 282px;
  height: 39px;
  background: #cff800;
  top: 0;
  margin: 45px 0 0 150px;
  left: 0;
  z-index: -1;
  @media (max-width: 481px) {
    width: 130px;
    margin: 45px 0 0 40px;
    height: 25px;
  }
  @media (max-width: 426px) {
    width: 155px;
    margin: 45px 0 0 40px;
    height: 30px;
  }
  @media (max-width: 376px) {
    width: 130px;
    margin: 45px 0 0 40px;
    height: 25px;
  }
  @media (max-width: 321px) {
    width: 130px;
    margin: 45px 0 0 40px;
    height: 25px;
  }
`;
export const Description = styled.h5`
  font-family: "Manrope";
  font-style: normal;
  font-weight: 800;
  font-size: 45px;
  line-height: 61px;
  color: #000000;
  margin-top: 30px;
  margin-bottom: 0;
  max-width: 1580px;
  @media only screen and (max-width: 981px) and (min-width: 769px) {
    font-size: 25px;
    width: 100%;
  }
  @media only screen and (max-width: 768px) and (min-width: 481px) {
    font-size: 22px;
    width: 100%;
  }
  @media only screen and (max-width: 480px) and (min-width: 321px) {
    line-height: 41px;
    width: 100%;
    font-size: 22px;
  }

  @media (max-width: 320px) {
    line-height: 31px;
    font-size: 16px;
    width: 100%;
  }
`;
export const TitleImage = styled.img`
  margin-bottom: 15px;

  @media only screen and (max-width: 768px) and (min-width: 321px) {
    width: 120px;
    margin-bottom: 5px;
    padding-left: 0;
  }
`;
export const Details = styled.div`
  width: 979px;
  margin-top: 85px;

  @media (max-width: 1441px) {
    width: 800px;
  }
  @media (max-width: 1025px) {
    width: 800px;
  }
  @media only screen and (max-width: 981px) and (min-width: 769px) {
    width: 100%;
  }
  @media only screen and (max-width: 768px) and (min-width: 200px) {
    width: 100%;
  }
  /* @media (max-width: 780px) {
    width: 800px;
  }
  @media (max-width: 481px) {
    width: 100%;
  }
  @media (max-width: 426px) {
    width: 95%;
  }
  @media (max-width: 376px) {
    width: 100%;
  }
  @media (max-width: 321px) {
    width: 100%;
  } */
`;
export const Detail = styled.div`
  background: ${(props) => props.bgColor};
  border-radius: 20px;
  padding: 31px 37px;
  margin-bottom: 43px;
  @media only screen and (max-width: 981px) and (min-width: 201px) {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
`;
export const DetailHead = styled.h1`
  font-family: "Manrope";
  font-style: normal;
  font-weight: 800;
  font-size: 50px;
  line-height: 68px;
  color: #1c2541;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  margin: 0;

  @media (max-width: 1921px) {
    font-size: 40px;
  }
  @media (max-width: 1441px) {
    font-size: 40px;
  }
  @media (max-width: 1025px) {
    font-size: 35px;
  }
  @media only screen and (max-width: 981px) and (min-width: 769px) {
    font-size: 35px;
  }
  /* @media (max-width: 780px) {
    font-size: 40px;
  } */
  @media only screen and (max-width: 768px) and (min-width: 200px) {
    font-size: 24px;
    line-height: 30px;
  }

  /* 
  @media (max-width: 481px) {
  }
  @media (max-width: 426px) {
    font-size: 15px;
    line-height: 20px;
  }
  @media (max-width: 376px) {
    font-size: 18px;
  }
  @media (max-width: 321px) {
    font-size: 16px;
  } */
`;
export const DetailDescription = styled.h6`
  font-family: "Manrope";
  font-style: normal;
  font-weight: 800;
  font-size: 30px;
  line-height: 41px;
  color: #1c2541;
  margin-top: 28px;
  margin-bottom: 28px;
  @media only screen and (max-width: 981px) and (min-width: 769px) {
    font-size: 23px;
  }
  @media only screen and (max-width: 769px) and (min-width: 481px) {
    font-size: 20px;
  }
  @media only screen and (max-width: 480px) and (min-width: 200px) {
    font-size: 16px;
  }
`;
export const Arrow = styled.img`
  @media (max-width: 426px) {
    width: 30px;
  }
`;

export const SideContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 481px) {
    width: 100%;
  }
  @media (max-width: 426px) {
    width: 100%;
  }
  @media (max-width: 376px) {
    width: 100%;
  }
  @media (max-width: 321px) {
    width: 100%;
  }
`;

export const Image = styled.img`
  @media (max-width: 1921px) {
    width: 500px;
  }
  @media (max-width: 1441px) {
    width: 400px;
  }
  @media (max-width: 1025px) {
    width: 300px;
  }
  @media only screen and (max-width: 768px) and (min-width: 481px) {
    width: 80%;
    margin-left: 30px;
    padding: 10px 40px;
  }
  @media only screen and (max-width: 480px) and (min-width: 250px) {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  /* @media (max-width: 480px) {
    width: 500px;
  }
  @media (max-width: 426px) {
    width: 300px;
  }
  @media (max-width: 376px) {
    width: 250px;
  }
  @media (max-width: 321px) {
    min-width: 250px;
  } */
`;

export const ImageContainerSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  display: none;
  @media only screen and (max-width: 981px) and (min-width: 201px) {
    display: block;
    margin-left: -10px;
    align-items: center;
    justify-content: center;
  }
  /* @media only screen and (max-width: 480px) and (min-width: 200px) {
    align-items: center;
    display: block;
    margin-left: -10px;
    justify-content: center;
  } */
`;
export const ImageContainerSectionOutside = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: 981px) and (min-width: 200px) {
    display: none;
  }
`;
