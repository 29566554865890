import React, { useState } from "react";
import {
  Container,
  Cards,
  Card,
  CardImg,
  CardTitleSection,
  CardTitle,
  CardSold,
  CardUser,
  CardUserStar,
  IntroSection,
  Heading,
  Description,
  TitleImage,
  Details,
  Detail,
  DetailHead,
  DetailDescription,
  Arrow,
  SideContainer,
  Image,
  CardUserLeft,
  CardRight,
  TitleBg,
  CardContainer,
  ImageContainerSection,
  ImageContainerSectionOutside,
} from "./NftRewardsStyle";
import card1 from "../../images/preview 1.png";
import card2 from "../../images/preview 2.png";
import card3 from "../../images/preview 3.png";
import card4 from "../../images/preview 4.png";
import card5 from "../../images/preview 5.png";
import star from "../../images/star.svg";
import arrowup from "../../images/arrowup.svg";
import arrowdown from "../../images/arrowdown.svg";
import group from "../../images/SmartNorth.lager.png";
import love from "../../images/love.svg";
import shoes from "../../images/Sneakeer.Plug.Fill.png";
import one from "../../images/1ofthem1s.t.png";
import figer from "../../images/ShotCaller.Premium.fill.png";

const data = [
  {
    id: 1,
    img: card1,
    title: "Smart North Tech Hub",
    sold: "$10 USD",
    name: "Base",
    count: "225 count",
  },
  {
    id: 2,
    img: card2,
    title: "Sneaker Plug",
    sold: "$10 USD",
    name: "Rare",
    count: "150 count",
  },
  {
    id: 3,
    img: card3,
    title: "Love You Cookie",
    sold: "$10 USD",
    name: "Epic",
    count: "50 count",
  },
  {
    id: 4,
    img: card4,
    title: "One of Them Ones",
    sold: "$10 USD",
    name: "Epic",
    count: "50 count",
  },
  {
    id: 5,
    img: card5,
    title: "Premium Parner Reward",
    sold: "$10 USD",
    name: "Legendary",
    count: "25 count",
  },
];

const rewardData = [
  {
    id: 1,
    heading: "Smart North Tech Hub Support",
    description:
      "Proceeds from this NFT will go to support WEB3 education for youth at the Smart North Tech Hub, located right outside of George Floyd Square.Inaugural NFT holders will be recognized as project supporters",
    bgColor: "#F3FFB6",
    imgSrc: group,
  },
  {
    id: 2,
    heading: "Sneaker Plug Ambassador",
    description:
      "10% discount (off entire purchase) at the Sneaker Plug PLUS “Close Friends” add on Instagram for EARLY ACCESS to exclusive drops",
    bgColor: "#EAFF81",
    imgSrc: shoes,
  },
  {
    id: 3,
    heading: "Love You Cookie Ambassador ",
    description:
      "(Tee-Shirt from Love You Cookie) Beyond making delicious gourmet cookies, Love You Cookie helps provide free mental health & wellness services, especially to BIPOC in our community",
    bgColor: "#CFF800",
    imgSrc: love,
  },
  {
    id: 4,
    heading: "One of Them Ones Ambassador ",
    description:
      "Limited Edition “ShotCaller x One of Them Ones” Collab T-Shirt",
    bgColor: "#BBE600",
    imgSrc: one,
  },
  {
    id: 5,
    heading: "Premium Partner Access ",
    description: "Premium Reward PLUS Early Access to next ShotCaller Drop",
    bgColor: "#BBE600",
    imgSrc: figer,
  },
];

const NftRewards = () => {
  const [open, setOpen] = useState(null);
  return (
    <Container>
      <CardContainer>
        <Cards>
          {data.map((item, i) => (
            <Card key={i + 1}>
              <CardImg src={item.img} alt="card" />
              <CardTitleSection>
                <CardTitle>{item.title}</CardTitle>
                <CardSold>{item.sold}</CardSold>
              </CardTitleSection>
              <CardUser>
                <CardUserLeft>
                  {item.name}
                  <CardUserStar src={star} alt="star" />
                </CardUserLeft>
                <CardRight>{item.count}</CardRight>
              </CardUser>
            </Card>
          ))}
        </Cards>
      </CardContainer>
      <IntroSection id="rewards">
        <Heading>NFT Rewards</Heading>
        <TitleBg></TitleBg>
        <Description>
          <TitleImage src={require("../../images/ShotCaller 2.svg").default} />
          {"  "}
          NFTs (non-fungible tokens) come with rewards linked to our beloved
          community partners. This inaugural NFT drop highlights rewards
          supporting vibrant black-owned Minneapolis-Saint Paul businesses, with
          sale proceeds going towards WEB3 education for youth at the Smart
          North Tech Hub, located right outside of George Floyd Square
        </Description>
        <SideContainer>
          <Details>
            {rewardData.map((item, i) => (
              <Detail key={i + 1} bgColor={item.bgColor}>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <DetailHead
                    onClick={() => setOpen(open === item.id ? null : item.id)}
                  >
                    {item.heading}
                  </DetailHead>
                  <Arrow
                    onClick={() => setOpen(open === item.id ? null : item.id)}
                    src={item.id === open ? arrowup : arrowdown}
                    alt="arrow"
                    style={{ cursor: "pointer" }}
                  />
                </div>
                {open === item.id && (
                  <DetailDescription>{item.description}</DetailDescription>
                )}
                {open === item.id && (
                  <ImageContainerSection key={i}>
                    <Image src={item?.imgSrc} />
                  </ImageContainerSection>
                )}
              </Detail>
            ))}
          </Details>
          {open === null && (
            <ImageContainerSectionOutside>
              <Image src={group} />
            </ImageContainerSectionOutside>
          )}
          {rewardData.map((item, i) => (
            <>
              {open === item.id && (
                <ImageContainerSectionOutside key={i}>
                  <Image src={item?.imgSrc} />
                </ImageContainerSectionOutside>
              )}
            </>
          ))}
        </SideContainer>
      </IntroSection>
    </Container>
  );
};
export default NftRewards;
