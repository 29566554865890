export const data = [
  {
    id: 1,
    question: "What is an NFT?",
    answer:
      "A non-fungible token (NFT) is a unit of data stored on a blockchain that certifies a digital asset to be unique and therefore not interchangeable. NFTs can be used to represent unique ownership of photos, videos, audio, and other types of digital files. ShotCaller creates NFTs as digital rewards highlighting our kick-ass local community partners. ",
  },
  {
    id: 2,
    question: "What is a blockchain?",
    answer:
      "A blockchain is a type of database that uses “blocks” instead of tables to store information. In general, blockchain is referred to as a digital ledger that is extremely difficult to hack due to its duplication of transactions and dissemination of information across the entire network of computer systems.",
  },
  {
    id: 3,
    question: "What is the ShotCaller MSP NFT?",
    answer:
      "The ShotCaller MSP NFT is part of the exclusive inaugural collection of NFTs by ShotCaller offered only to ShotCaller supporters that either (1) purchase the NFT via the ShotCaller NFT homepage or (2) Win a ShotCaller NFT during an upcoming ShotCaller contest.",
    link: `  to be notified of contest dates`,
    clickHere: `click here`,
    modal: true,
  },
  {
    id: 4,
    question: "What rewards are associated with the ShotCaller MSP NFTs?",
    answer: `Each ShotCaller NFT is tied to a specific reward for the NFT owner. These rewards differ at each NFT tier & help contribute towards supporting our community partners.`,
    link: `  for reward details`,
    seeHere: `see here`,
    address: `#rewards`,
  },
  {
    id: 5,
    question: "When will the ShotCaller MSP NFT be released?",
    answer:
      "ShotCaller supporters can purchase the NFTs starting mid-September, or earn a chance to win an NFT by making game picks on the ShotCaller app on upcoming September 'featured contest' dates. Fans will receive instructions via email to redeem their ShotCaller MSP NFT on or around September 30th (or about within 14 days after an eligible contest date).",
    link: `  to be notified of contest dates`,
    clickHere: `click here`,
    modal: true,
  },
  {
    id: 6,
    question: "How will the ShotCaller MSP NFT be released?",
    answer: `Supporters who purchase the ShotCaller MSP NFT or win an NFT via eligible ShotCaller free-2-play contests will receive an email and/or detailed "in-app" redemption instructions. These NFTs will be randomly assigned to customers who purchase the NFTs on the website or win an NFT during featured contest gameplay.`,
  },
  {
    id: 7,
    question: "How much will the ShotCaller MSP NFT cost?",
    answer:
      "That depends. The cost to purchase a ShotCaller MSP NFT outright is $10. ShotCaller supporters can also win an NFT for FREE via upcoming contests (no purchase or payment necessary). ",
  },
  {
    id: 8,
    question: "How many NFTs am I eligible to receive?",
    answer:
      "There is no limit to how many NFTs you can purchase. You will have the opportunity to win only 1 NFT per contest game, regardless of the number of entries.",
  },
  {
    id: 9,
    question: "What blockchain is ShotCaller building its infrastructure on?",
    answer:
      "ShotCaller is using the Flow infrastructure to build out its marketplace. Flow is a proof of stake blockchain designed to be the foundation of Web3 and the open metaverse, supporting consumer-scale decentralized applications, NFTs, DeFi, DAOs, PFP projects, and more. Powered by Cadence, an original programming language built specifically for digital assets, Flow empowers developers to innovate and push the limits that will bring the next billion to Web3. Created by a team that has consistently delivered industry-leading consumer-scale experiences including CryptoKitties, NBA Top Shot, and NFL ALL DAY, Flow is an open, decentralized platform with a thriving ecosystem of creators from top brands, development studios, venture-backed startups, crypto leaders, and more.",
  },
  {
    id: 10,
    question: " Which NFT will I receive at purchase?",
    answer:
      "The NFT you receive will be selected at random to help support our collective community. Random selection help us highlight all of our partners in the collection. Collect ALL 5 to be recognized as MSP MVP!",
  },
];
