import { Avatar } from "antd";
import styled from "styled-components";

export const NftShowSectionContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 40px;
  @media only screen and (max-width: 1921px) and (min-width: 1442px) {
    width: 100%;
    margin-top: 100px;
    display: flex;
    flex-direction: row;
  }
  @media only screen and (max-width: 1441px) and (min-width: 1025px) {
    width: 100%;
    margin-top: 100px;
    display: flex;
    flex-direction: row;
  }
  @media only screen and (max-width: 1025px) and (min-width: 769px) {
    width: 100%;
    margin-top: 100px;
    display: flex;
    flex-direction: row;
  }
  @media only screen and (max-width: 768px) and (min-width: 201px) {
    width: 100%;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
  }
  /* @media only screen and (max-width: 480px) and (min-width: 321px) {
    width: 100%;
    margin-top: 50px;
    display: flex;
    flex-direction: column;
  }

  @media (max-width: 321px) {
    width: 100%;
    margin-top: 50px;
    display: flex;
    flex-direction: column;
  } */
`;

export const LeftSection = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 150px;
  @media only screen and (max-width: 1921px) and (min-width: 1442px) {
    width: 50%;
    margin-left: 0px;
    padding: 15px 30px;
  }
  @media only screen and (max-width: 1441px) and (min-width: 1026px) {
    width: 50%;
    margin-left: 0px;
    padding: 15px 30px;
  }
  @media only screen and (max-width: 1025px) and (min-width: 769px) {
    width: 50%;
    margin-left: 0px;
    padding: 15px 30px;
  }
  @media only screen and (max-width: 768px) and (min-width: 481px) {
    width: 50%;
    margin-left: 0px;
    padding: 15px 30px;
  }

  @media only screen and (max-width: 480px) and (min-width: 321px) {
    width: 100%;
    margin-left: 0px;
    padding: 5px;
  }

  @media (max-width: 321px) {
    width: 100%;
    margin-left: 0px;
    padding: 5px;
  }
`;
export const NftImage = styled.img`
  @media only screen and (max-width: 1921px) and (min-width: 1442px) {
    width: 700px;
  }
  @media only screen and (max-width: 1441px) and (min-width: 1026px) {
    width: 600px;
    padding: 5px;
  }
  @media only screen and (max-width: 1025px) and (min-width: 769px) {
    width: 500px;
    padding: 5px;
  }
  @media only screen and (max-width: 768px) and (min-width: 481px) {
    width: 360px;
    padding: 5px;
  }
  @media only screen and (max-width: 480px) and (min-width: 321px) {
    width: 300px;
    padding: 5px;
  }

  @media (max-width: 320px) {
    width: 280px;
    padding: 5px;
  }
`;
export const RightSection = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @media only screen and (max-width: 1441px) and (min-width: 1026px) {
    width: 50%;
    margin-bottom: 30px;
  }
  @media only screen and (max-width: 1026px) and (min-width: 769px) {
    width: 50%;
    margin-bottom: 30px;
  }
  @media only screen and (max-width: 768px) and (min-width: 481px) {
    width: 100%;
    margin-bottom: 30px;
  }
  @media only screen and (max-width: 480px) and (min-width: 200px) {
    width: 100%;
    margin-bottom: 30px;
  }
`;
export const RightTitle = styled.h3`
  font-family: "Manrope";
  font-style: normal;
  font-weight: 800;
  font-size: 40px;
  line-height: 55px;
  /* identical to box height */
  text-align: center;
  color: #1c2541;
  @media only screen and (max-width: 1441px) and (min-width: 1026px) {
    font-size: 40px;
    line-height: 55px;
  }
  @media only screen and (max-width: 1025px) and (min-width: 769px) {
    font-size: 40px;
    line-height: 55px;
  }
  @media only screen and (max-width: 768px) and (min-width: 481px) {
    font-size: 32px;
    line-height: 55px;
  }
  @media only screen and (max-width: 481px) and (min-width: 321px) {
    font-size: 26px;
    line-height: 35px;
  }

  @media (max-width: 320px) {
    font-size: 28px;
    line-height: 25px;
  }
`;
export const AvatarSection = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: row;
  margin-top: 30px;
  @media only screen and (max-width: 1921px) and (min-width: 1442px) {
    width: 65%;
    padding: 5px;
    justify-content: center;
    margin-top: 20px;
    margin-left: 10px;
  }
  @media only screen and (max-width: 1441px) and (min-width: 1026px) {
    width: 65%;
    padding: 5px;
    justify-content: center;
    margin-top: 20px;
    margin-left: 10px;
  }
  @media only screen and (max-width: 1025px) and (min-width: 769px) {
    width: 50%;
    padding: 5px;
    justify-content: center;
    margin-top: 20px;
    margin-left: 20px;
  }
  @media only screen and (max-width: 768px) and (min-width: 481px) {
    width: 85%;
    padding: 5px;
    justify-content: center;
    margin-top: 20px;
    margin-left: 20px;
  }
  @media only screen and (max-width: 480px) and (min-width: 321px) {
    width: 100%;
    padding: 5px;
    justify-content: center;
  }
  @media (max-width: 320px) {
    width: 100%;
    padding: 5px;
  }
`;
export const LeftAvatar = styled.div`
  position: relative;
  /* width: 80%; */
  @media only screen and (max-width: 1441px) and (min-width: 1026px) {
    padding: 10px;
  }
  @media only screen and (max-width: 1025px) and (min-width: 769px) {
    padding: 10px;
  }
  @media only screen and (max-width: 768px) and (min-width: 481px) {
    padding: 10px;
  }
  @media only screen and (max-width: 480px) and (min-width: 321px) {
    padding: 5px;
  }
`;

export const AvatarImg = styled(Avatar)`
  width: 110px;
  height: 110px;

  @media only screen and (max-width: 1441px) and (min-width: 1026px) {
    width: 110px;
    height: 110px;
  }

  @media only screen and (max-width: 1025px) and (min-width: 769px) {
    width: 110px;
    height: 110px;
  }

  @media only screen and (max-width: 768px) and (min-width: 481px) {
    width: 100px;
    height: 100px;
  }

  @media only screen and (max-width: 480px) and (min-width: 321px) {
    width: 65px;
    height: 65px;
  }
  @media (max-width: 320px) {
    padding: 5px;
    width: 60px;
    height: 60px;
  }
`;

export const TickImage = styled.img`
  position: absolute;
  bottom: -4px;
  right: 0px;
  @media only screen and (max-width: 1441px) and (min-width: 1026px) {
    width: 40px;
    bottom: 10px;
    right: 5px;
  }
  @media (max-width: 1025px) {
    width: 35px;
    bottom: 10px;
    right: 5px;
  }
  @media (max-width: 769px) {
    width: 45px;
    bottom: 10px;
    right: 10px;
  }
  @media (max-width: 481px) {
    width: 35px;
    bottom: 10px;
    right: 10px;
  }
  @media (max-width: 426px) {
    width: 35px;
    bottom: 10px;
    right: 10px;
  }
  @media (max-width: 376px) {
    width: 30px;
    bottom: 10px;
    right: 10px;
  }
  @media (max-width: 321px) {
    width: 20px;
    bottom: 10px;
    right: 10px;
  }
`;
export const RightAvatar = styled.h4`
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 41px;
  /* identical to box height */
  color: #56e39f;
  @media only screen and (max-width: 1441px) and (min-width: 1026px) {
    font-size: 30px;
  }
  @media only screen and (max-width: 1025px) and (min-width: 769px) {
    font-size: 30px;
  }
  @media only screen and (max-width: 768px) and (min-width: 481px) {
    font-size: 30px;
  }
  @media only screen and (max-width: 480px) and (min-width: 321px) {
    font-size: 17px;
  }
  @media (max-width: 320px) {
    font-size: 17px;
    line-height: 30px;
  }
`;
export const Description = styled.div`
  width: 75%;
  margin-left: 200px;
  margin-top: 50px;

  font-family: "Manrope";
  font-style: normal;
  font-weight: 800;
  font-size: 25px;
  line-height: 34px;
  color: #000000;
  @media only screen and (max-width: 1441px) and (min-width: 1026px) {
    width: 100%;
    margin-left: 20px;
    margin-top: 0px;
    font-size: 25px;
    line-height: 40px;
    padding: 10px 30px;
  }
  @media only screen and (max-width: 1025px) and (min-width: 769px) {
    width: 100%;
    margin-left: 20px;
    margin-top: 0px;
    font-size: 25px;
    line-height: 40px;
    padding: 10px 30px;
  }
  @media only screen and (max-width: 768px) and (min-width: 481px) {
    width: 80%;
    margin-left: 20px;
    margin-top: 0px;
    font-size: 25px;
    line-height: 40px;
    padding: 10px 30px;
  }
  @media only screen and (max-width: 480px) and (min-width: 321px) {
    width: 100%;
    margin-left: 20px;
    margin-bottom: 10px;
    margin-top: 0px;
    font-size: 20px;
    line-height: 40px;
  }
  @media (max-width: 320px) {
    width: 100%;
    margin-left: 0px;
    margin-top: 0px;
    font-size: 16px;
    line-height: 34px;
    padding: 10px;
  }
`;

export const PartnerSection = styled.div`
  width: 75%;
  margin-left: 200px;
  margin-top: 50px;
  /* background-color: grey; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @media only screen and (max-width: 1025px) and (min-width: 769px) {
    width: 100%;
    margin-left: 0px;
    margin-top: 10px;
  }
  @media only screen and (max-width: 768px) and (min-width: 481px) {
    width: 100%;
    margin-left: 0px;
    margin-top: 10px;
  }
  @media only screen and (max-width: 481px) and (min-width: 321px) {
    width: 100%;
    margin-left: 0px;
    margin-top: 10px;
  }

  @media (max-width: 320px) {
    width: 100%;
    margin-left: 0px;
    margin-top: 10px;
  }
`;

export const TopSection = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const CenterSection = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  @media only screen and (max-width: 380px) and (min-width: 206px) {
    flex-direction: column;
    width: 100%;
    margin-top: 0px;
  }
`;

export const PartnerTitle = styled.span`
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 34px;
  text-align: center;
  color: #7d8cac;
  @media only screen and (max-width: 780px) and (min-width: 481px) {
    font-size: 20px;
    line-height: 34px;
  }
  @media only screen and (max-width: 480px) and (min-width: 200px) {
    font-size: 16px;
    line-height: 34px;
  }
`;
export const PartnerTotal = styled.span`
  font-family: "Manrope";
  font-style: normal;
  font-weight: 800;
  font-size: 22px;
  line-height: 41px;
  /* identical to box height */
  text-align: center;
  color: #1c2541;
  @media only screen and (max-width: 780px) and (min-width: 482px) {
    font-size: 16px;
    line-height: 34px;
  }
  @media only screen and (max-width: 480px) and (min-width: 200px) {
    font-size: 14px;
    line-height: 34px;
  }
`;
export const LeftTitle = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @media only screen and (max-width: 780px) and (min-width: 200px) {
    width: 100%;
  }
`;
export const RightTitletext = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @media only screen and (max-width: 780px) and (min-width: 200px) {
    width: 100%;
  }
`;
// export const TopSection=styled.div``
// export const TopSection=styled.div``

export const ButtonDiv = styled.div`
  width: 60%;
  height: 65px;
  ${(props) => props.user && `display:"none`}
  margin-left: 110px;
  margin-top: 50px;
  background: #ffc439;
  box-shadow: 0px 11px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  @media only screen and (max-width: 1441px) and (min-width: 1026px) {
    width: 80%;
    height: 55px;
    margin-left: 0px;
  }
  @media only screen and (max-width: 769px) and (min-width: 481px) {
    width: 80%;
    height: 55px;
    margin-left: 0px;
  }
  @media only screen and (max-width: 480px) and (min-width: 377px) {
    width: 90%;
    height: 55px;
    margin-left: 0px;
  }
  @media only screen and (max-width: 376px) and (min-width: 200px) {
    width: 90%;
    height: 45px;
    margin-left: 0px;
  }
`;
export const PayPalText = styled.span`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 52px;
  color: #365bb3;
  @media (max-width: 321px) {
    font-size: 25px;
  }
`;
export const ConnectToWalletText = styled.span`
  font-family: "DM Sans";
  font-style: normal;
  ${(props) => props.user && `display:"none`}

  font-weight: 700;
  font-size: 35px;
  line-height: 52px;
  color: #485470;
  @media only screen and (max-width: 1441px) and (min-width: 1026px) {
    font-size: 32px;
  }
  @media only screen and (max-width: 769px) and (min-width: 481px) {
    font-size: 33px;
  }
  @media only screen and (max-width: 480px) and (min-width: 377px) {
    font-size: 28px;
  }
  @media only screen and (max-width: 376px) and (min-width: 200px) {
    font-size: 22px;
  }
`;
export const NoInvoLeftDiv = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: 50px;
  flex-direction: column;
  @media only screen and (max-width: 769px) and (min-width: 481px) {
    width: 100%;
  }
  @media only screen and (max-width: 480px) and (min-width: 377px) {
    width: 100%;
  }
  @media only screen and (max-width: 376px) and (min-width: 200px) {
    width: 100%;
  }
`;
export const NoInvoText = styled.span`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 52px;
  color: #485470;
`;
export const WaitListButton = styled.button`
  width: 100%;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
  color: #485470;
  /* color: #FFD700; */
  border-radius: 20px;
  background-color: #ffd700;
`;

export const GetMspUpdateContainer = styled.div`
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 30px 50px;
  cursor: pointer;
  @media only screen and (max-width: 1921px) and (min-width: 1442px) {
    width: 40%;
    margin: 60px 70px;
  }
  @media only screen and (max-width: 1441px) and (min-width: 1026px) {
    width: 60%;
    margin: 60px 50px;
  }
  @media only screen and (max-width: 769px) and (min-width: 481px) {
    width: 80%;
    /* margin: 20px 10px; */
  }
  @media only screen and (max-width: 480px) and (min-width: 377px) {
    width: 100%;
    margin: 20px 10px;
  }
  @media only screen and (max-width: 376px) and (min-width: 200px) {
    width: 100%;
    margin: 0px 5px;
  }
`;

export const GetMspButton = styled.div`
  width: 70%;
  background: #bbe600;
  box-shadow: 0px 10px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 39px;
  text-align: center;
  color: #1c2541;
  padding: 15px 20px;
  @media only screen and (max-width: 1921px) and (min-width: 1442px) {
    width: 80%;
    font-size: 25px;
  }
  @media only screen and (max-width: 1441px) and (min-width: 1026px) {
    width: 80%;
    font-size: 25px;
  }
  @media only screen and (max-width: 769px) and (min-width: 481px) {
    width: 100%;
    font-size: 25px;
  }
  @media only screen and (max-width: 480px) and (min-width: 377px) {
    width: 90%;
    font-size: 20px;
  }
  @media only screen and (max-width: 376px) and (min-width: 200px) {
    width: 90%;
    font-size: 16px;
  }
`;
// export const NftShowSectionContainer = styled.div``;
// export const NftShowSectionContainer = styled.div``;
// export const NftShowSectionContainer = styled.div``;
// export const NftShowSectionContainer = styled.div``;
// export const NftShowSectionContainer = styled.div``;
