import styled from "styled-components";
import bg from "../../images/bgFinger.png";
export const CheckOutContainer = styled.div`
  width: 100%;
  height: 100%;
  background: url(${bg});
  background-position: top center;
  background-repeat: no-repeat;
  background-size: contain;
  z-index: -1;
`;
