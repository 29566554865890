import React from "react";
import styled from "styled-components";
import bg from "../../images/bgFinger.png";

const Container = styled.div`
  width: 100%;
  background: url(${bg});
  background-position: top center;
  background-repeat: no-repeat;
  background-size: contain;
  @media (max-width: 1099px) {
    background: none;
  }
`;

const CenterBg = (props) => {
  return <Container>{props.children}</Container>;
};

export default CenterBg;
