import styled from "styled-components";

export const HeaderContainer = styled.div`
  width: 100%;
  height: 5rem;
  display: flex;
  align-items: center;
  padding: 20px;
  background: #fcfbfb;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  @media (max-width: 1441px) {
    width: 100%;
  }
  @media (max-width: 1025px) {
    width: 100%;
  }
  @media (max-width: 780px) {
    width: 100%;
    display: none;
  }
  @media (max-width: 480px) {
    width: 100%;
    display: none;
  }
  @media (max-width: 426px) {
    width: 100%;
    display: none;
  }
  @media (max-width: 376px) {
    width: 100%;
    display: none;
  }
  @media (max-width: 320px) {
    display: none;
    width: 100%;
  }
`;

export const MobileHeadercontainer = styled.div`
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background: #fcfbfb;
  display: none;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  @media (max-width: 780px) {
    width: 100vw;
    display: block;
  }
`;
export const LogoMobile = styled.img`
  width: 50%;
`;

export const NavigationContainerMobile = styled.div`
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: white;
  z-index: 1;
  -webkit-box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
  @media (min-width: 780px) {
    display: none;
  }
`;

export const FlexBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const HamburgerButton = styled.div`
  .container {
    display: inline-block;
    cursor: pointer;
  }

  .bar1,
  .bar2,
  .bar3 {
    width: 30px;
    height: 3px;
    background-color: #333;
    margin: 4px 0;
    transition: 0.4s;
  }

  .change .bar1 {
    -webkit-transform: rotate(-45deg) translate(-6px, 4px);
    transform: rotate(-45deg) translate(-6px, 4px);
  }

  .change .bar2 {
    opacity: 0;
  }

  .change .bar3 {
    -webkit-transform: rotate(45deg) translate(-6px, -5px);
    transform: rotate(45deg) translate(-6px, -5px);
  }
`;

export const LogoContainer = styled.div`
  width: 22%;
  display: flex;
  align-items: center;
  margin-left: 2rem;
  @media (max-width: 426px) {
    margin-left: 0;
    width: 50%;
  }
`;

export const LogoContainerMobile = styled.div`
  display: flex;
  align-items: center;
`;

export const LogoWrapper = styled.div``;
export const Logo = styled.img`
  border-radius: 10px;
`;

export const TitleImage = styled.img`
  /* font-family: "Luckiest Guy";
  font-style: normal;
  font-weight: 400;
  font-size: 35px;
  line-height: 35px;
  text-transform: uppercase;
  margin-left: 20px; */
  /* color: rgba(0, 0, 0, 0.8); */
  /* color: #fc2b5a; */
  margin-left: 10px;
  /* @media (max-width: 425px) {
    width: 150px;
  } */
  @media only screen and (max-width: 781px) and (min-width: 320px) {
    width: 200px;
    ${(props) =>
      props.bigger &&
      `
    width:220px;
    margin-left:-5px
    `}
  }
  /*   
  @media (max-width: 321px) {
    width: 200px;
    ${(props) =>
    props.bigger &&
    `
    width:220px;
    margin-left:-5px
    `}
  } */
`;

export const TitleImageMobile = styled.img`
  width: 130px;
  margin-left: -22px;
`;

export const NavigationContainer = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
`;
export const NavTitle = styled.a`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 22px;
  cursor: pointer;
  text-decoration: none;
  color: #485470;
`;
export const NavTitleMobile = styled.a`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 22px;
  cursor: pointer;
  text-decoration: none;
  color: #485470;
  padding: 10px;
  margin: 10px;
`;
export const HeaderRight = styled.div`
  width: 26%;
  display: flex;
  align-items: center;
  margin-right: 1rem;
  justify-content: space-around;
  @media (max-width: 780px) {
    width: 100%;
  }
`;
export const HeaderRightIcons = styled.div`
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  @media (max-width: 780px) {
    width: 100%;
  }
`;
export const HeaderRightProfile = styled.div`
  width: 60%;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fcfbfb;
  border: 1px solid #485470;
  border-radius: 24px;
  /* transform: matrix(-1, 0, 0, 1, 0, 0); */
`;
export const UserIcon = styled.div`
  border-right: 1px solid #485470;
  height: 100%;
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const WalletWrapper = styled.div`
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  @media (max-width: 780px) {
    width: 140px;
  }
`;
export const ConnectWalletTitle = styled.span`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 22px;

  color: #485470;
  @media (max-width: 780px) {
    font-size: 12px;
  }
`;
export const SvgIcon = styled.img`
  width: 60px;
`;

export const LaptopViewContainer = styled.img`
  @media (min-width: 599px) {
    // display: none;
  }
`;
