import React, { useState } from "react";
import {
  FaqContainer,
  FaqHeader,
  QuestionContainer,
  QuestionTitle,
  Image,
  AnswerContainer,
} from "./faq-style.js";
import Plus from "../../images/plus.png";
import Min from "../../images/min.png";
import { data } from "./data.js";
import ModalSignup from "../modal/index.js";
import ModalSignup2 from "../modal/modal2.js";

const Faq = () => {
  return (
    <FaqContainer id="faq">
      <FaqHeader>Frequently Asked Questions</FaqHeader>
      {data?.map((data, index) => {
        return (
          <QuestionAnswer
            key={index}
            question={data?.question}
            answer={data?.answer}
            link={data?.link}
            address={data?.address}
            modal={data?.modal}
            clickHere={data?.clickHere}
            seeHere={data?.seeHere}
          />
        );
      })}
    </FaqContainer>
  );
};

const QuestionAnswer = ({
  question,
  answer,
  link,
  address,
  modal,
  clickHere,
  seeHere,
}) => {
  const [answerShow, setAnswerShow] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisible2, setIsModalVisible2] = useState(false);

  const handleOnClick = () => {
    setAnswerShow(!answerShow);
  };
  console.log(clickHere);
  return (
    <>
      <QuestionContainer>
        <div>
          <QuestionTitle>{question}</QuestionTitle>
        </div>
        <div onClick={handleOnClick}>
          <Image
            src={answerShow ? Min : Plus}
            alt={`${answerShow ? "min" : "plus"}`}
          />
        </div>
      </QuestionContainer>
      {answerShow && (
        <AnswerContainer>
          <span>
            {answer}
            {address && (
              <a
                href={address}
                // style={{ textDecoration: "underline" }}
              >
                <span>
                  <span style={{ textDecoration: "underline" }}>{seeHere}</span>
                  <span style={{ color: "black" }}>{link}</span>
                </span>
              </a>
            )}
            {modal === true && (
              <a onClick={() => setIsModalVisible2(true)}>
                <span>
                  <span style={{ textDecoration: "underline" }}>
                    {clickHere}
                  </span>
                  <span style={{ color: "black" }}>{link}</span>
                </span>
              </a>
            )}
          </span>
        </AnswerContainer>
      )}
      <ModalSignup2
        setIsModalVisible={setIsModalVisible2}
        isModalVisible={isModalVisible2}
        title={"Get MSP NFT Project Updates"}
      />
      <ModalSignup
        setIsModalVisible={setIsModalVisible}
        isModalVisible={isModalVisible}
      />
    </>
  );
};

export default Faq;
