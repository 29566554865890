import React from "react";
import {
  HeroContainer,
  HeroRedImageContainer,
  HeroRedImage,
  HeroTitleText,
  TopSubTextSection,
  SpanText,
  LeftText,
  BottomButton,
  PurchaseButton,
  CancelButton,
  Buttontext,
  CancelButtontext,
  ShadowCardImage,
  CardSection,
  CardImage,
  CardImage2,
  CardTop,
  CardBottom,
  CartTitleTopSection,
  CartTitleBottomSection,
  CardTitle,
  CardPrice,
  OwnerName,
  Rank,
  QrCodeImage,
  JustifyBetween,
  BottomImageConatiner,
  BottomImageConatinerMobile,
  PriceLeft,
  PriceLeftTop,
  PriceLeftBottom,
  PriceRight,
  Whytext,
  ItemCenter,
  DisplayOnlyMobile,
} from "./herosectionStyle";
import baseball from "../../../images/baseball 1.png";
import ShadowImage from "../../../images/Rectangle 23937.png";
import NftImages from "../../../images/preview 5.png";
import QrCode from "../../../images/Vector.png";
import { Link } from "react-router-dom";
import { Popover } from "antd";

const HeroSectionResponsive = () => {
  const content = (
    <p style={{ color: "black", fontSize: "17px", lineHeight: "30px" }}>
      Random selection help us highlight all of our partners <br /> in the
      collection, plus its MoRE FuN that way!😜🤟
    </p>
  );
  const contentMobile = (
    <p
      style={{
        color: "black",
        fontSize: "12px",
        lineHeight: "20px",
        width: "100%",
      }}
    >
      Random selection help us highlight all of our partners in the collection,
      plus its MoRE FuN that way!😜🤟
    </p>
  );
  return (
    <div>
      <HeroContainer>
        <HeroRedImageContainer>
          <HeroRedImage src={baseball} alt="hero-img" />
          <HeroTitleText>ShotCaller MSP NFT</HeroTitleText>
          <TopSubTextSection>
            by <SpanText>@ MNFT</SpanText>
          </TopSubTextSection>
        </HeroRedImageContainer>
        <JustifyBetween>
          <div>
            <LeftText>
              Welcome to the ShotCaller Community! <br /> <br /> Each NFT comes
              with a different partner perk,
              <br /> <br /> Collect ALL 5 to be recognized as MSP MVP!
            </LeftText>
            <BottomButton>
              <a href={"#purchase"}>
                <PurchaseButton>
                  <Buttontext>Purchase</Buttontext>
                </PurchaseButton>
              </a>
              <Link to={"/"}>
                <CancelButton>
                  <CancelButtontext>Cancel</CancelButtontext>
                </CancelButton>
              </Link>
            </BottomButton>
          </div>
          <div>
            <CardSection>
              <div>
                <CardImage></CardImage>
                <CardImage2>
                  <CardTop src={NftImages} alt="" />
                  <CardBottom>
                    <CartTitleTopSection>
                      <CardTitle>Premium Partner</CardTitle>
                      <CardPrice>$10 USD</CardPrice>
                    </CartTitleTopSection>
                  </CardBottom>
                  <CartTitleBottomSection>
                    <OwnerName>playShotCaller</OwnerName>
                    <Rank>#2</Rank>
                    <QrCodeImage src={QrCode} />
                  </CartTitleBottomSection>
                </CardImage2>
              </div>
            </CardSection>
          </div>
        </JustifyBetween>
        <BottomImageConatiner>
          <ItemCenter>
            <PriceLeft>
              <PriceLeftTop>$10 USD</PriceLeftTop>
              <PriceLeftBottom>Sale Price</PriceLeftBottom>
            </PriceLeft>
            <PriceRight>
              *The NFT you receive will be selected at random to help support
              our collective community
              <Popover
                content={content}
                placement="bottom"
                style={{ backgroundColor: "#eaff81" }}
              >
                <Whytext>why?</Whytext>
              </Popover>
            </PriceRight>
          </ItemCenter>
        </BottomImageConatiner>
      </HeroContainer>
      <DisplayOnlyMobile>
        <BottomImageConatinerMobile>
          <ItemCenter>
            <PriceLeft>
              <PriceLeftTop>$10 USD</PriceLeftTop>
              <PriceLeftBottom>Sale Price</PriceLeftBottom>
            </PriceLeft>
            <PriceRight>
              *The NFT you recieve will be selected at random to help support
              our collective community
              <Popover
                content={contentMobile}
                placement="bottom"
                style={{ backgroundColor: "#eaff81" }}
              >
                <Whytext>why?</Whytext>
              </Popover>
            </PriceRight>
          </ItemCenter>
        </BottomImageConatinerMobile>
      </DisplayOnlyMobile>
    </div>
  );
};

export default HeroSectionResponsive;
