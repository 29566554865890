import React, { useState } from "react";
import ReactDOM from "react-dom";
import {
  AvatarImg,
  AvatarSection,
  ButtonDiv,
  CenterSection,
  ConnectToWalletText,
  Description,
  GetMspButton,
  GetMspUpdateContainer,
  LeftAvatar,
  LeftSection,
  LeftTitle,
  NftImage,
  NftShowSectionContainer,
  NftShowWrapper,
  NoInvoLeftDiv,
  NoInvoText,
  PartnerSection,
  PartnerTitle,
  PartnerTotal,
  PayPalButtonDiv,
  PayPalText,
  RightAvatar,
  RightSection,
  RightTitle,
  RightTitletext,
  TickImage,
  TopSection,
  WaitListButton,
} from "./style";
import NftImg from "../../../images/image 11.png";
import AvatarImage from "../../../images/Ellipse 743.png";
import TickImages from "../../../images/Group 76238.png";
import logo from "../../../images/logo.png";
import { Avatar } from "antd";
import * as fcl from "@onflow/fcl";
import { useEffect } from "react";
import { loadScript } from "@paypal/paypal-js";
import {
  PayPalButtons,
  PayPalHostedFieldsProvider,
} from "@paypal/react-paypal-js";
import axios from "axios";
import PaymentSuccessModal from "../../modal/PaymentSuccessModal";
import ModalSignup from "../../modal";
import ModalSignup2 from "../../modal/modal2";

fcl.config({
  "app.detail.title": "ShotCaller",
  "accessNode.api": "https://rest-mainnet.onflow.org", // Mainnet: "https://rest-mainnet.onflow.org"
  "discovery.wallet": "https://fcl-discovery.onflow.org/authn", // Mainnet: "https://fcl-discovery.onflow.org/authn"
});

// eslint-disable-next-line no-undef
const PayPalButton = paypal.Buttons.driver("react", { React, ReactDOM });

export default function NftShowSection() {
  const [user, setUser] = useState(null);
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [stock, setStock] = useState(null);
  const [stock2, setStock2] = useState(0);

  const [isModalVisible, setIsModalVisibleP] = useState(false);
  const [signupModal, setSignUpModal] = useState(false);
  const [signupModal2, setSignUpModal2] = useState(false);

  useEffect(() => {
    const checkInvo = async () => {
      const res = await axios.get(
        "https://app.playshotcaller.com/shotcall/api/v1/users/nft/inventoryLeft"
      );
      setStock(res.data.inventory);
    };
    checkInvo();
  }, []);

  const createOrder = async (data, actions) => {
    // console.log("upper data", data, actions);
    const res = await axios.get(
      "https://app.playshotcaller.com/shotcall/api/v1/users/nft/inventoryLeft"
    );
    if (res?.data?.inventory <= 0) {
      setStock(res.data.inventory);
      return;
    }

    return actions.order.create({
      purchase_units: [
        {
          amount: {
            currency_code: "USD",
            value: "10.0",
          },
          description: "ShotCaller MSP NFT",
        },
      ],
    });
  };
  const onApprove = async (data, actions) => {
    const response = await actions.order.capture();

    PurchaseSuccess(data, response, actions);
    EmailSend();
    setIsModalVisibleP(true);
  };

  useEffect(() => {
    fcl.currentUser().subscribe(setUser);
    return () => {
      fcl.unauthenticate();
    };
  }, []);

  const handleBlocto = async () => {
    const response = await fcl.authenticate();
    if (response.addr !== null) {
      setEmail(response?.services[0].scoped.email);
      setAddress(response?.addr);
      const res = await axios.get(
        "https://app.playshotcaller.com/shotcall/api/v1/users/nft/inventoryLeft"
      );
      setStock(res.data.inventory);
      // console.log(res.data);
    } else {
      return () => {
        console.log("wallet not connected");
      };
    }
  };

  const PurchaseSuccess = async (data, response, actions) => {
    await axios
      .post(
        "https://app.playshotcaller.com/shotcall/api/v1/users/nft/purchaseSuccess",
        {
          email: email,
          walletAddress: address,
          transactionId: data?.orderID,
        }
      )
      .then((res) => {})
      .catch((err) => {});
  };

  const EmailSend = async () => {
    await axios
      .post(
        "https://app.playshotcaller.com/shotcall/api/v1/users/nft/successPurchaseEmail",
        { email: email }
      )
      .then((res) => {})
      .catch((err) => {});
  };

  // useEffect(() => {
  //   setSignUpModal2(true);
  // }, []);

  return (
    <>
      <ModalSignup2
        setIsModalVisible={setSignUpModal2}
        isModalVisible={signupModal2}
        title={"Get MSP NFT Project Updates"}
      />
      <GetMspUpdateContainer onClick={() => setSignUpModal2(true)}>
        <GetMspButton>Get MSP NFT Project Updates</GetMspButton>
      </GetMspUpdateContainer>
      <NftShowSectionContainer>
        <LeftSection>
          <NftImage src={NftImg} alt="image" />
        </LeftSection>
        <RightSection>
          <RightTitle>ShotCaller MSP NFT</RightTitle>
          <AvatarSection>
            <LeftAvatar>
              <AvatarImg size="large" src={logo} />
              <TickImage src={TickImages} alt="pp" />
            </LeftAvatar>
            <RightAvatar>@playShotCaller</RightAvatar>
          </AvatarSection>
          <Description id="purchase">
            Proceeds from this NFT will go to support WEB3 education for youth
            at the Smart North Tech Hub, located right outside of George Floyd
            Square
          </Description>
          <PartnerSection>
            <TopSection>
              <PartnerTitle>SMART NORTH TECH HUB</PartnerTitle>
              <PartnerTotal>225 total</PartnerTotal>
            </TopSection>
            <CenterSection>
              <LeftTitle>
                <PartnerTitle>LOVE YOU COOKIE</PartnerTitle>
                <PartnerTotal>50 total</PartnerTotal>
              </LeftTitle>
              <RightTitletext>
                <PartnerTitle>SNEAKER PLUG</PartnerTitle>
                <PartnerTotal>150 total</PartnerTotal>
              </RightTitletext>
            </CenterSection>
            <CenterSection>
              <LeftTitle>
                <PartnerTitle>ONE OF THEM ONES</PartnerTitle>
                <PartnerTotal>50 total</PartnerTotal>
              </LeftTitle>
              <RightTitletext>
                <PartnerTitle>PREMIUM PARTNER</PartnerTitle>
                <PartnerTotal>25 total</PartnerTotal>
              </RightTitletext>
            </CenterSection>
          </PartnerSection>

          {stock >= 1 ? (
            user?.addr ? (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "30px",
                }}
              >
                <PayPalButton
                  style={{ shape: "rect", height: 40, layout: "horizontal" }}
                  createOrder={(data, actions) => createOrder(data, actions)}
                  onApprove={(data, actions) => onApprove(data, actions)}
                />
              </div>
            ) : (
              <ButtonDiv onClick={() => handleBlocto()}>
                <ConnectToWalletText>Connect to wallet</ConnectToWalletText>
              </ButtonDiv>
            )
          ) : (
            <>
              <NoInvoLeftDiv>
                <NoInvoText>*No NFTs left in inventory</NoInvoText>
                <WaitListButton onClick={() => setSignUpModal(true)}>
                  Join next project Waitlist
                </WaitListButton>
              </NoInvoLeftDiv>
              <ModalSignup
                setIsModalVisible={setSignUpModal}
                isModalVisible={signupModal}
              />
            </>
          )}

          {
            // user?.addr && stock1 >= 1 && (
            //   <div
            //     style={{
            //       width: "100%",
            //       display: "flex",
            //       alignItems: "center",
            //       justifyContent: "center",
            //       marginTop: "30px",
            //     }}
            //   >
            //     <PayPalButton
            //       style={{ shape: "rect", height: 40, layout: "horizontal" }}
            //       createOrder={(data, actions) => createOrder(data, actions)}
            //       onApprove={(data, actions) => onApprove(data, actions)}
            //     />
            //   </div>
            // )
            //    : (
            //     <>
            //       <NoInvoLeftDiv>
            //         <NoInvoText>*No NFTs left in inventory</NoInvoText>
            //         <WaitListButton onClick={() => setSignUpModal(true)}>
            //           Join next project Waitlist
            //         </WaitListButton>
            //       </NoInvoLeftDiv>
            //       <ModalSignup
            //         setIsModalVisible={setSignUpModal}
            //         isModalVisible={signupModal}
            //       />
            //     </>
            //   )
            // ) : (
            //   <ButtonDiv onClick={() => handleBlocto()}>
            //     <ConnectToWalletText>Connect to wallet</ConnectToWalletText>
            //   </ButtonDiv>
            // )}
          }
        </RightSection>

        {/* {paypal && (
        <PayPalScriptProvider options={{ "client-id": "test" }}>
        <PayPalButtons style={{ layout: "horizontal" }} />
        </PayPalScriptProvider>
      )} */}
        {/* check email for nft details */}
        {/* <button
        onClick={() => {
          setIsModalVisibleP(true);
          EmailSend();
          PurchaseSuccess();
        }}
        style={{
          backgroundColor: "tomato",
          color: "white",
          border: "none",
          padding: "5px 15px",
          cursor: "pointer",
          fontFamily: "Inter",
          fontWeight: "700",
        }}
      >
        Testing
      </button> */}
        <PaymentSuccessModal
          isModalVisible={isModalVisible}
          setIsModalVisibleP={setIsModalVisibleP}
        />
      </NftShowSectionContainer>
    </>
  );
}
