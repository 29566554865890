import React from "react";
import GetStartedSection from "../../components/GetStarted";
import CreatorSection from "../../components/CreatorSection";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import Faq from "../../components/Faq";
import NftRewards from "../../components/NftRewards";
import About from "../../components/About";
import NftPartners from "../../components/PartnerSection";
import HowItsWork from "../../components/HowItsWork";
import CenterBg from "../../components/CenterBg";
import BloctoModal from "../../components/BloctoModal";

export default function LandingPageOne() {
  return (
    <>
      <div>
        <Header />
        <GetStartedSection />
        <About />
        <HowItsWork />
        <CenterBg>
          <NftRewards />
        </CenterBg>
        <CreatorSection />
        <CenterBg>
          <Faq />
        </CenterBg>
        <NftPartners />
        <Footer />
      </div>
    </>
  );
}
