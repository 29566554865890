import React, { useState } from "react";
import "antd/dist/antd.css";
import axios from "axios";
import "./index.css";

import { Modal, message } from "antd";
export default function ModalSignup({
  isModalVisible,
  setIsModalVisible,
  title,
}) {
  const [email, setEmail] = useState("");

  const handleClick = async () => {
    await axios
      .post(
        "https://app.playshotcaller.com/shotcall/api/v1/users/nft/newsletter",
        { email: email }
      )
      .then((res) => {
        setIsModalVisible(false);
        message.success("Thanks for joining");
      })
      .catch((e) => message.error("Something went wromg!"));
  };

  return (
    <>
      <Modal
        width={700}
        height={800}
        // title="Basic Modal"
        footer={null}
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        bodyStyle={{
          width: "100%",
          height: "300px",
          padding: "40px",
          background: "rgba(255, 255, 255, 0.95)",

          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div style={{ width: "100%" }}>
          <h1
            style={{
              textAlign: "center",
              fontSize: "30px",
              fontFamily: "Manrope",
              fontWeight: "500",
            }}
          >
            Join Waitlist
          </h1>
          {/*  */}
          <div class="login-page">
            <div class="form">
              <form action="#" class="login-form" onSubmit={handleClick}>
                <input
                  type="email"
                  placeholder="email"
                  required
                  onChange={(e) => setEmail(e.target.value)}
                />
                <button>Join</button>
              </form>
            </div>
          </div>

          {/*  */}
        </div>
      </Modal>
    </>
  );
}
