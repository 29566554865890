import React, { useEffect, useState } from "react";
import { Button, Modal } from "antd";
import { Helmet } from "react-helmet";

// import "./style.css";
import BloctoImage from "../../images/blocto.logo.png";

import * as fcl from "@onflow/fcl";
import {
  BottomWrapper,
  Btntext,
  ButtonDiv,
  Detail,
  Easy,
  Image,
  InnerBottomContainer,
  InnerCenterContainer,
  InnerTopContainer,
  Link,
  ModalContainer,
  Signup,
} from "./style";

fcl.config({
  "app.detail.title": "ShotCaller",
  "accessNode.api": "https://rest-mainnet.onflow.org", // Mainnet: "https://rest-mainnet.onflow.org"
  "discovery.wallet": "https://fcl-discovery.onflow.org/authn", // Mainnet: "https://fcl-discovery.onflow.org/authn"
});

export default function BloctoModal() {
  const [visible, setVisible] = useState(false);
  const [user, setUser] = useState();
  const [walletConnect, setWalletConnect] = useState();
  const [back, setBack] = useState();

  console.log(user);
  console.log("wallet", walletConnect);
  useEffect(() => {
    setVisible(true);
    fcl.currentUser().subscribe(setUser);
    console.log(user);
    return () => {
      fcl.unauthenticate();
    };
  }, []);

  const logIn = async () => {
    setVisible(false);
    const response = await fcl.authenticate();
    console.log("response", response);
    if (response.addr !== null) {
      setWalletConnect(response);
      console.log("user set", response.addr);
    } else {
      setWalletConnect(false);
      console.log("wallet not connected");
    }
  };

  const handleBack = (e) => {
    console.log(user);
    setBack(false);
    setVisible(false);
  };
  return (
    <div>
      {user?.addr && (
        <Helmet>
          <script>
            if (messageHandler){" "}
            {
              // eslint-disable-next-line no-undef
              messageHandler.postMessage(user.addr)
            }
          </script>
        </Helmet>
      )}
      {walletConnect === false && (
        <Helmet>
          <script>
            if (backButtonHandler){" "}
            {
              // eslint-disable-next-line no-undef
              popupClosedHandler.postMessage("Popup closed")
            }
          </script>
        </Helmet>
      )}
      {back === false && (
        <Helmet>
          <script>
            if (backButtonPressHandler){" "}
            {
              // eslint-disable-next-line no-undef
              backButtonPressHandler.postMessage("Back button press")
            }
          </script>
        </Helmet>
      )}

      <>
        <Modal
          width={500}
          className="modal"
          visible={visible}
          closable={false}
          footer={false}
          centered
        >
          <ModalContainer>
            <InnerTopContainer>
              <Image src={BloctoImage} />
              {/* <h4 className="title">Your Entrance to block chain app</h4> */}
            </InnerTopContainer>
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Easy>
                The easiest way to navigate the <br /> blockchain world
              </Easy>
            </div>
            <InnerCenterContainer>
              <Detail>
                Please connect to the Blocto wallet <br /> by clicking “Connect
                to wallet”
              </Detail>
              <Signup>SIGNUP IS FREE </Signup>
            </InnerCenterContainer>
            <InnerBottomContainer>
              <BottomWrapper>
                <ButtonDiv onClick={() => logIn()}>
                  <Btntext>Connect to wallet</Btntext>
                </ButtonDiv>
                <Link onClick={handleBack}>Back</Link>
              </BottomWrapper>
            </InnerBottomContainer>
          </ModalContainer>
        </Modal>
      </>
    </div>
  );
}
