import styled from "styled-components";

export const HeroSectionConatiner = styled.div`
  width: 100%;
  @media only screen and (max-width: 1720px) {
    display: none;
  }
`;

export const HeroSectionConatinerResponsive = styled.div`
  display: block;
  @media only screen and (min-width: 1721px) {
    display: none;
  }
`;

export const ImageContainer = styled.div`
  width: 100%;
  padding: 60px 40px 60px 150px;
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const ImageItem = styled.img`
  width: 100%;
`;

export const TopImage = styled.img`
  width: 100%;
`;

export const TopImageContainer = styled.div`
  width: 90%;
  position: absolute;
  top: 10;
  right: 90px;
`;
export const TopTextSection = styled.h1`
  position: absolute;
  bottom: 30px;
  left: 30px;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 800;
  font-size: 40px;
  line-height: 55px;
  /* identical to box height */

  text-align: center;

  color: #1c2541;
`;

export const TopSubTextSection = styled.span`
  position: absolute;
  bottom: 10px;
  left: 40px;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 34px;
  color: #000000;
`;

export const SpanText = styled.span`
  /* color: #e20000; */
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 34px;
`;

export const CenterImageConatiner = styled.div`
  width: 100%;
`;

export const LeftTextWrapper = styled.div`
  width: 42%;
  position: absolute;
  left: 10%;
  top: 32%;
  display: flex;
  flex-direction: column;
`;
export const LeftText = styled.h5`
  font-family: "Manrope";
  font-style: normal;
  font-weight: 800;
  font-size: 25px;
  line-height: 34px;

  color: #000000;
`;

export const BottomButton = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 70px;
`;
export const PurchaseButton = styled.div`
  background: #bbe600;
  width: 245px;
  height: 66px;
  box-shadow: 0px 10px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
export const Buttontext = styled.h4`
  margin-top: 10px;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 39px;

  color: #485470;
`;
export const CancelButton = styled.div`
  margin-left: 30px;
  width: 245px;
  height: 66px;
  background: #f3ffb6;
  border: 1px solid #000000;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
export const CancelButtontext = styled.h4`
  margin-top: 10px;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 33px;

  color: #485470;
`;
export const RightTextWrapper = styled.div`
  width: 100%;
  position: relative;
`;

export const CardSection = styled.div`
  position: absolute;
  top: 100px;
  right: 100px;

  /* transform: rotate(10.46deg); */
  /* -webkit-box-shadow: 8px 11px 0px 8px rgba(255, 255, 255, 1);
  -moz-box-shadow: 8px 11px 0px 8px rgba(255, 255, 255, 1);
  box-shadow: 8px 11px 0px 8px rgba(255, 255, 255, 1); */
`;

export const ShadowCardImage = styled.img`
  z-index: -1;
`;

export const CardImage = styled.div`
  width: 353px;
  height: 480px;
  background-color: white;
  border: 1px solid black;
  box-shadow: 5px 5px 15px 0px grey;
  z-index: 9999;
  position: absolute;
  top: -5px;
  left: 20px;
  transform: rotate(10.46deg);
  display: flex;
  flex-direction: column;
`;

export const CardTop = styled.img`
  padding: 20px;
`;
export const CardBottom = styled.div`
  width: 100%;
  padding: 20px;
`;
export const CartTitleTopSection = styled.div`
  width: 100%;
  display: flex;
  align-items: left;
  justify-content: space-between;
`;

export const CartTitleBottomSection = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

export const CardTitle = styled.h4`
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  color: #485470;
`;
export const CardPrice = styled.span`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #99a7c7;
`;
// export const CardBottom = styled.div``;
export const OwnerName = styled.div`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 46px;
  color: #99a7c7;
  display: flex;
  flex: 1;
`;
export const Rank = styled.span`
  font-family: "Space Grotesk";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  /* line-height: 150px; */
  /* or 375% */
  color: #485470;
  margin-right: 30px;
  text-transform: uppercase;
`;
export const QrCodeImage = styled.img`
  transform: rotate(-9deg);
`;

export const BottomImageConatiner = styled.div`
  width: 90%;
`;

export const BottomImageWrapper = styled.div`
  width: 50%;
`;
export const ImageBottom = styled.img`
  width: 50%;
  position: absolute;
  bottom: 30px;
`;

export const PriceSectionContainer = styled.div`
  position: relative;
`;
export const PriceLeft = styled.div`
  width: 30%;
  position: absolute;
  bottom: 35px;
  left: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: -15px;
`;

export const PriceLeftTop = styled.h3`
  font-family: "Manrope";
  font-style: normal;
  font-weight: 800;
  font-size: 30px;
  line-height: 41px;
  /* identical to box height */

  text-align: center;

  color: #1c2541;
`;
export const PriceLeftBottom = styled.span`
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 27px;
  /* text-align: center; */
  text-transform: uppercase;

  color: #7d8cac;
`;

export const PriceRight = styled.div`
  width: 70%;
  position: absolute;
  bottom: 0px;
  right: 0px;

  font-family: "Manrope";
  font-style: normal;
  font-weight: 800;
  font-size: 28px;
  line-height: 41px;
  color: #1c2541;
`;

export const Whytext = styled.span`
  margin-left: 20px;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 27px;
  text-align: center;
  cursor: pointer;
  color: #7d8cac;
`;

export const GetMspUpdateContainer = styled.div`
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 30px 50px;
`;

export const GetMspButton = styled.div`
  width: 70%;
  background: #bbe600;
  box-shadow: 0px 10px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 39px;
  text-align: center;
  color: #1c2541;
  padding: 15px 20px;
`;
// export const Data=styled.div``
// export const Data=styled.div``
// export const Data=styled.div``
// export const Data=styled.div``
// export const Data=styled.div``
// export const Data=styled.div``
// export const Data=styled.div``
// export const Data=styled.div``
// export const Data=styled.div``
// export const Data=styled.div``
