import React from "react";
import HeroSection from "../../components/CheckOut/HeroSection";
import NftShowSection from "../../components/CheckOut/NftShowSection";
import Header from "../../components/Header";
import { CheckOutContainer } from "./style";

export default function CheckOut() {
  return (
    <CheckOutContainer>
      <Header />
      <HeroSection />
      <NftShowSection />
    </CheckOutContainer>
  );
}
