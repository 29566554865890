import React, { useEffect } from "react";
import {
  HeroSectionConatiner,
  BottomImageConatiner,
  CenterImageConatiner,
  ImageBottom,
  ImageContainer,
  ImageItem,
  TopImage,
  TopImageContainer,
  TopTextSection,
  TopSubTextSection,
  LeftTextWrapper,
  RightTextWrapper,
  LeftText,
  BottomButton,
  PurchaseButton,
  CancelButton,
  Buttontext,
  CancelButtontext,
  SpanText,
  BottomImageWrapper,
  PriceLeft,
  PriceRight,
  PriceSectionContainer,
  Whytext,
  PriceLeftTop,
  PriceLeftBottom,
  CardSection,
  ShadowCardImage,
  CardImage,
  CardTop,
  CardBottom,
  CartTitleTopSection,
  CartTitleBottomSection,
  CardTitle,
  CardPrice,
  OwnerName,
  Rank,
  QrCodeImage,
  HeroSectionConatinerResponsive,
} from "./style";
import bg from "../../../images/bgcheckout.png";
import baseball from "../../../images/baseball 1.png";
import bottomImage from "../../../images/Rectangle 23936.png";
import ShadowImage from "../../../images/Rectangle 23937.png";
import NftImages from "../../../images/preview 5.png";
import QrCode from "../../../images/Vector.png";
import HeroSectionResponsive from "./HeroSectionResponsive";
import { Link } from "react-router-dom";
import NftShowSection from "../NftShowSection";
import { Popover } from "antd";

export default function HeroSection() {
  const content = (
    <p style={{ color: "black", fontSize: "17px", lineHeight: "30px" }}>
      Random selection help us highlight all of our partners <br /> in the
      collection, plus its MoRE FuN that way!😜🤟
    </p>
  );

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "auto" });
  }, []);
  return (
    <>
      <HeroSectionConatiner>
        <ImageContainer>
          <TopImageContainer>
            <TopImage src={baseball} />
            <div style={{ display: "flex" }}>
              <TopTextSection>ShotCaller MSP NFT</TopTextSection>
              <TopSubTextSection>
                by <SpanText>@ MNFT</SpanText>
              </TopSubTextSection>
            </div>
          </TopImageContainer>
          <CenterImageConatiner>
            <LeftTextWrapper>
              <LeftText>
                Welcome to the ShotCaller Community! <br /> <br /> Each NFT
                comes with a different partner perk,
                <br /> <br /> Collect ALL 5 to be recognized as MSP MVP!
              </LeftText>
              <BottomButton>
                <a href={"#purchase"}>
                  <PurchaseButton>
                    <Buttontext>Purchase</Buttontext>
                  </PurchaseButton>
                </a>
                <Link to={"/"}>
                  <CancelButton>
                    <CancelButtontext>Cancel</CancelButtontext>
                  </CancelButton>
                </Link>
              </BottomButton>
            </LeftTextWrapper>
            <RightTextWrapper>
              <CardSection>
                <ShadowCardImage src={ShadowImage} />
                <CardImage>
                  <CardTop src={NftImages} alt="" />
                  <CardBottom>
                    <CartTitleTopSection>
                      <CardTitle>Premium Partner</CardTitle>
                      <CardPrice>$10 USD</CardPrice>
                    </CartTitleTopSection>
                    <CartTitleBottomSection>
                      <OwnerName>playShotCaller</OwnerName>
                      <Rank>#2</Rank>
                      <QrCodeImage src={QrCode} />
                    </CartTitleBottomSection>
                  </CardBottom>
                </CardImage>
              </CardSection>
            </RightTextWrapper>
            <ImageItem src={bg} alt="image" />
          </CenterImageConatiner>
          <BottomImageConatiner>
            <BottomImageWrapper>
              <ImageBottom src={bottomImage} />
              <PriceSectionContainer>
                <PriceLeft>
                  <PriceLeftTop>$10 USD</PriceLeftTop>
                  <PriceLeftBottom>Sale Price</PriceLeftBottom>
                </PriceLeft>
                <PriceRight>
                  *The NFT you receive will be selected at random to help
                  support our collective community
                  <Popover
                    content={content}
                    placement="bottom"
                    style={{ backgroundColor: "#eaff81" }}
                  >
                    <Whytext>why?</Whytext>
                  </Popover>
                </PriceRight>
              </PriceSectionContainer>
            </BottomImageWrapper>
          </BottomImageConatiner>
        </ImageContainer>
      </HeroSectionConatiner>
      {/* responsive */}
      <HeroSectionConatinerResponsive>
        <HeroSectionResponsive />
      </HeroSectionConatinerResponsive>
      {/*  */}
    </>
  );
}
