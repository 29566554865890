import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

export const firebaseConfig = {
  apiKey: "AIzaSyBTtVJone-D8sgQtUNUkzPd-PAupPvP8sU",
  authDomain: "shotcaller-d66e3.firebaseapp.com",
  //   databaseURL: "https://project-id.firebaseio.com",
  projectId: "shotcaller-d66e3",
  storageBucket: "shotcaller-d66e3.appspot.com",
  messagingSenderId: "676224447833",
  appId: "1:676224447833:web:ae4ff7410f87f4589c0848",
  measurementId: "G-D7EPM0JHJE",
};

const app = initializeApp(firebaseConfig);
console.log("appp", app);

export const analytics = getAnalytics();
