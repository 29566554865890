import styled from "styled-components";

export const FaqContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @media (max-width: 1441px) {
    width: 100rem;
  }
  @media (max-width: 1025px) {
    width: 100rem;
  }
  /* @media (max-width: 780px) {
    width: 100%;
  } */
  @media only screen and (max-width: 981px) and (min-width: 269px) {
    width: 100%;
  }
  /*   
  @media (max-width: 480px) {
    width: 100%;
  }
  @media (max-width: 426px) {
    width: 100%;
  }
  @media (max-width: 376px) {
    width: 100%;
  }
  @media (max-width: 321px) {
    width: 100%;
  } */
`;

export const FaqHeader = styled.div`
  font-family: "Manrope";
  font-style: normal;
  font-weight: 800;
  font-size: 80px;
  line-height: 109px;
  color: #000000;
  margin: 139px 0px 115px 0px;
  @media (max-width: 376px) {
    font-size: 60px;
  }
  @media only screen and (max-width: 981px) and (min-width: 769px) {
    font-size: 36px;
    line-height: 39px;
    padding: 10px;
    margin: 50px 10px;
  }
  @media only screen and (max-width: 768px) and (min-width: 581px) {
    font-size: 35px;
    line-height: 59px;
    margin: 40px 20px;
  }
  @media only screen and (max-width: 580px) and (min-width: 481px) {
    font-size: 30px;
    line-height: 59px;
    margin: 40px 20px;
  }
  @media only screen and (max-width: 480px) and (min-width: 416px) {
    font-size: 27px;
    line-height: 59px;
    margin: 40px 20px;
  }
  @media only screen and (max-width: 416px) and (min-width: 320px) {
    font-size: 22px;
    line-height: 29px;
    margin: 20px 5px;
  }
`;

export const QuestionTitle = styled.h2`
  font-family: "Manrope";
  font-style: normal;
  font-weight: 800;
  font-size: 50px;
  line-height: 68px;
  color: #000000;
  @media (max-width: 780px) {
    font-size: 16px;
    line-height: 25px;
  }
  @media (max-width: 376px) {
    line-height: 22px;
    font-size: 14px;
  }
  @media (max-width: 321px) {
    line-height: 20px;
    font-size: 12px;
  }
`;

export const QuestionContainer = styled.div`
  background: #f1f8ff;
  border-radius: 20px;
  width: 80vw;
  padding: 19px 59px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 38px;
  @media (max-width: 1441px) {
    width: 90%;
  }
  @media (max-width: 1025px) {
    width: 90%;
  }
  @media (max-width: 780px) {
    width: 90%;
  }
  @media (max-width: 480px) {
    width: 90%;
  }
  @media (max-width: 426px) {
    width: 90%;
    padding: 10px 10px;
    display: flex;
    align-items: center;
    margin-bottom: 18px;
  }
  @media (max-width: 376px) {
    width: 90%;
  }
  @media (max-width: 320px) {
    width: 90%;
    margin-right: 0px;
  }
`;

export const Image = styled.img`
  width: 60px;
  height: 60px;
  margin-top: 2px;
  cursor: pointer;
  @media (max-width: 780px) {
    width: 50px;
    height: 50px;
  }
`;

export const AnswerContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 80%;
  border-radius: 20px;
  padding: 19px 59px;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  font-size: 46px;
  line-height: 68px;
  color: #000000;
  margin: -38px 0 0 38px;
  @media (max-width: 780px) {
    width: 100%;
    font-size: 14px;
    line-height: 18px;
    margin: 0;
    padding: 10px 30px;
  }
  @media (max-width: 376px) {
    width: 100%;
    line-height: 18px;
    font-size: 16px;
    margin: 0;
  }
  @media (max-width: 321px) {
    line-height: 18px;
    width: 100%;
    font-size: 12px;
    margin: 0;
  }
`;
