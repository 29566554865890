import React from "react";
import {
  BgColor,
  BgLine,
  CardDescription,
  CardDetailWrapper,
  CardImage,
  CardSection,
  CardTitle,
  HowItsWorkCardSection,
  HowItsWorkContainer,
  HowItsWorkTitle,
  HowItsWorkWrapper,
  TitleBackground,
} from "./style";

import buy from "../../images/buy.png";
import play from "../../images/Group 76364.png";
import redeem from "../../images/redeem.png";

export default function HowItsWork() {
  return (
    <HowItsWorkContainer>
      <BgLine></BgLine>
      <HowItsWorkWrapper>
        <HowItsWorkTitle>
          {/* <BgColor></BgColor> */}
          <span style={{ position: "relative" }}>
            How It
            <TitleBackground></TitleBackground>
            Works
          </span>
        </HowItsWorkTitle>
        <HowItsWorkCardSection>
          <CardDetailWrapper>
            <CardSection>
              <CardTitle>Purchase</CardTitle>
              <CardImage src={buy} alt="image" />
              <CardDescription>
                Purchase an NFT to support our community partners
              </CardDescription>
            </CardSection>
          </CardDetailWrapper>
          <CardDetailWrapper>
            <CardSection mt>
              <CardTitle>Play</CardTitle>
              <CardImage src={play} alt="image" />
              <CardDescription>
                Score a chance to win a free NFT via upcoming featured contests{" "}
                <br />
                (no purchase necessary)
              </CardDescription>
            </CardSection>
          </CardDetailWrapper>
          <CardDetailWrapper>
            <CardSection mb mt>
              <CardTitle>Redeem</CardTitle>
              <CardImage src={redeem} alt="image" />
              <CardDescription>
                Redeem NFT to unlock fun partner <br /> perks
              </CardDescription>
            </CardSection>
          </CardDetailWrapper>
        </HowItsWorkCardSection>
      </HowItsWorkWrapper>
    </HowItsWorkContainer>
  );
}
