import React from "react";
import {
  PartnerBlock,
  PartnerContainer,
  ImgContainer,
  Img1,
  Img2,
  Img3,
  Img4,
  Img5,
  Img6,
  Img7,
  Img8,
  Title,
  TitleBg,
  TitleBlock,
  DisplayNoneMobile,
  ImageMobileView,
  DisplayOnlyMobile,
} from "./styled";
import OneOfThemOnes from "../../images/1ofthem1s 1.png";
import Flow from "../../images/flow logo 1.png";
import MNFT from "../../images/MNFT 2.png";
import SmartNorth from "../../images/SmartNorth 1.png";
import SneakerPlug from "../../images/SneakerPlug 1.png";
import AeternaMedia from "../../images/Aeterna Media.2 - Copy.png";
import ShotCaller from "../../images/ShotCaller 3.png";
import LoveYouCookie from "../../images/LoveYouCookie 1.png";
import { GetStartedButton } from "../GetStarted/getStartedStyle";
import { Link } from "react-router-dom";
const Image = [
  {
    imageName: Flow,
    url: "https://flow.com/",
  },
  {
    imageName: LoveYouCookie,
    url: "https://loveyoucookie.com/",
  },
  {
    imageName: SneakerPlug,
    url: "https://www.instagram.com/thesneakerplugmn/?hl=en",
  },
  {
    imageName: SmartNorth,
    url: "https://smartnorth.org/projects/",
  },
  {
    imageName: ShotCaller,
    url: "https://www.playshotcaller.com/",
  },
  {
    imageName: OneOfThemOnes,
    url: "https://www.facebook.com/One-of-them-Ones-105251231194878/",
  },
  {
    imageName: MNFT,
    url: "https://www.endaomints.xyz/",
  },
  {
    imageName: AeternaMedia,
    url: "https://aeterna.media/",
  },
];

const ImageMobile = [
  {
    imageName: SmartNorth,
    url: "https://smartnorth.org/projects/",
  },
  {
    imageName: MNFT,
    url: "https://www.endaomints.xyz/",
  },
  {
    imageName: LoveYouCookie,
    url: "https://loveyoucookie.com/",
  },
  {
    imageName: SneakerPlug,
    url: "https://www.instagram.com/thesneakerplugmn/?hl=en",
  },
  {
    imageName: OneOfThemOnes,
    url: "https://www.facebook.com/One-of-them-Ones-105251231194878/",
  },
  {
    imageName: AeternaMedia,
    size: "resize",
    url: "https://aeterna.media/",
  },
  {
    imageName: Flow,
    url: "https://flow.com/",
  },
  {
    imageName: ShotCaller,
    url: "https://www.playshotcaller.com/",
  },
];
export default function NftPartners() {
  return (
    <PartnerContainer>
      <TitleBlock>
        <Title>
          <span style={{ position: "relative" }}>
            NFT Proj
            <TitleBg></TitleBg>
          </span>
          ect Partners
        </Title>
      </TitleBlock>
      <DisplayNoneMobile>
        <PartnerBlock>
          <ImgContainer>
            <a href={Image[0].url} target="_blank">
              <Img1 src={Image[0].imageName} alt={Image[0].url} />
            </a>
            <a href={Image[4].url} target="_blank">
              <Img2 src={Image[4].imageName} alt={Image[4].url} />
            </a>
          </ImgContainer>
          <ImgContainer>
            <a href={Image[1].url} target="_blank">
              <Img3 src={Image[1].imageName} alt={Image[1].url} />
            </a>
          </ImgContainer>
          <ImgContainer>
            <a href={Image[2].url} target="_blank">
              <Img4 src={Image[2].imageName} alt={Image[2].url} />
            </a>
            <a
              href={Image[5].url}
              target="_blank"
              style={{ marginTop: "40px", marginLeft: "20px", zIndex: "-1" }}
            >
              <Img5 src={Image[5].imageName} alt={Image[5].url} />
            </a>
          </ImgContainer>
          <ImgContainer>
            <a href={Image[3].url} target="_blank">
              <Img6 src={Image[3].imageName} alt={Image[3].url} />
            </a>
            <a href={Image[6].url} target="_blanck">
              <Img7 src={Image[6].imageName} alt={Image[6].url} />
            </a>
          </ImgContainer>
          <ImgContainer>
            <a href={Image[7].url} target="_blank">
              <Img8 src={Image[7].imageName} alt={Image[7].url} />
            </a>
          </ImgContainer>
        </PartnerBlock>
      </DisplayNoneMobile>

      {/* mobile view */}
      <DisplayOnlyMobile>
        {ImageMobile?.map((data, index) => {
          return (
            <div key={index}>
              <a href={data?.url} target="_blank">
                <ImageMobileView
                  AeternaMedia={data?.size ? true : false}
                  src={data?.imageName}
                  alt={data?.url}
                />
              </a>
            </div>
          );
        })}
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "30px",
          }}
        >
          <Link to={"/checkout"}>
            <GetStartedButton w="220px" bg="#0075eb">
              Get Started
            </GetStartedButton>
          </Link>
        </div>
      </DisplayOnlyMobile>
    </PartnerContainer>
  );
}
