import React from "react";
import {
  CreatorContainer,
  SideImg,
  Title,
  Description,
  Button,
  TitleBackGround,
  TitleBackGroundArt,
  ArtContainer,
  ArtImgContainer,
  ArtImgText,
  ButtonArt,
  TitleArt,
} from "./style.js";
import CreatorImg from "../../images/creatorSectionImg.svg";
import ArtImg from "../../images/artMainImg.svg";
import ArtText from "../../images/artText.svg";

const CreatorSection = () => {
  return (
    <div id="creators">
      <Creator
        title="Creator"
        description={`Our mission is simple, "Bringing communities closer together through the power of sports & technology."`}
        founderName={`- Michael Hunter Dansbury`}
        founder={`Founder @ ShotCaller`}
        imgSrc={CreatorImg}
        buttonText="Follow on Instagram"
      />
      <Art
        title="Art"
        title2="Direction"
        description={`Style developed by MNFT, a youth-lead WEB3 collective increasing education surrounding and access to WEB3 among the MSP High School Community`}
        imgSrc={ArtImg}
        buttonText="Learn more about MNFT"
        textimgsrc={ArtText}
      />
    </div>
  );
};

const Creator = ({
  title,
  description,
  founder,
  imgSrc,
  buttonText,
  founderName,
}) => {
  return (
    <CreatorContainer>
      <div>
        <SideImg src={imgSrc} alt="demo img" />
      </div>
      <div style={{ position: "relative" }}>
        <Title>
          {title}
          <TitleBackGround></TitleBackGround>
        </Title>
        <Description>
          {description} <br />
          {founderName} <br />
          {founder}
        </Description>
        <a href="https://www.instagram.com/playshotcaller/" target="_blank">
          <Button>{buttonText}</Button>
        </a>
      </div>
    </CreatorContainer>
  );
};

const Art = ({
  title,
  description,
  founder,
  imgSrc,
  buttonText,
  textimgsrc,
  title2,
}) => {
  return (
    <ArtContainer>
      <div>
        <Title art style={{ position: "relative" }}>
          {title}&nbsp;{title2}
          <TitleBackGroundArt></TitleBackGroundArt>
        </Title>
        <Description>
          {description} <br />
          {founder}
        </Description>
        <a href="https://www.endaomints.xyz/" target="_blank">
          <ButtonArt>{buttonText}</ButtonArt>
        </a>
      </div>
      <ArtImgContainer>
        <SideImg art src={imgSrc} alt="demo img" />
        <ArtImgText src={textimgsrc} alt="text img" />
      </ArtImgContainer>
    </ArtContainer>
  );
};

export default CreatorSection;
