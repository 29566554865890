import styled from "styled-components";

export const PartnerContainer = styled.div`
  width: 100%;
  padding: 40px;
  margin-bottom: 100px;
  @media (max-width: 780px) {
    padding: 20px;
  }
  @media (max-width: 426px) {
    width: 100%;
  }
  @media (max-width: 376px) {
    width: 100%;
  }
  @media (max-width: 321px) {
    width: 100%;
  }
`;

export const Title = styled.h1`
  font-family: "Manrope";
  font-style: normal;
  font-weight: 800;
  font-size: 80px;
  line-height: 109px;
  line-height: 109px;

  color: #1c2541;
  @media (max-width: 780px) {
    font-size: 33px;
  }
  @media (max-width: 376px) {
    font-size: 25px;
    line-height: 59px;
  }
  @media (max-width: 321px) {
    font-size: 20px;
    line-height: 59px;
  }
`;

export const TitleBlock = styled.div`
  position: relative;
  @media (max-width: 1441px) {
    width: 100%;
  }
  @media (max-width: 1025px) {
    width: 100%;
  }
  @media (max-width: 780px) {
    width: 97%;
  }
  @media (max-width: 480px) {
    width: 100%;
  }
  @media (max-width: 426px) {
    width: 100%;
  }
  @media (max-width: 376px) {
    width: 100%;
  }
  @media (max-width: 321px) {
    width: 100%;
  }
`;
export const TitleBg = styled.span`
  position: absolute;
  width: 282px;
  height: 39px;
  background: #cff800;
  top: 0;
  margin: 45px 0 0 10px;
  left: 0;
  z-index: -1;
  @media (max-width: 426px) {
    /* width: 140px; */
  }

  @media only screen and (max-width: 768px) and (min-width: 481px) {
    width: 150px;
    height: 30px;
    margin: 10px 0px 0px 0px;
  }
  @media only screen and (max-width: 480px) and (min-width: 377px) {
    width: 150px;
    height: 30px;
    margin: 10px 0px 0px 0px;
  }
  @media only screen and (max-width: 376px) and (min-width: 201px) {
    width: 110px;
    height: 20px;
    margin: 10px 0px 0px 0px;
  }
`;

export const DisplayNoneMobile = styled.div`
  @media (max-width: 981px) {
    display: none;
  }
`;

export const DisplayOnlyMobile = styled.div`
  display: block;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @media (min-width: 980px) {
    display: none;
  }
`;

export const ImageMobileView = styled.img`
  max-width: ${(props) => (props.AeternaMedia ? "200px" : "250px")};
  margin: 20px 0 20px 0;
  @media (max-width: 400px) {
    max-width: ${(props) => (props.AeternaMedia ? "150px" : "200px")};
  }
`;

export const PartnerBlock = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 50px 5%;
  @media (max-width: 768px) {
    padding: 0;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  @media (max-width: 481px) {
    padding: 0;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  @media (max-width: 426px) {
    padding: 0;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  @media (max-width: 376px) {
    padding: 0;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  @media (max-width: 321px) {
    padding: 0;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
`;
export const PartnerImage = styled.img`
  // width: 55%;
  margin: 0px 10px;
  cursor: pointer;
`;
export const PartnerImageRedirect = styled.a`
  display: flex;
  flex-direction: row;
`;

export const ImgContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: flex-start;
`;

export const Img1 = styled.img`
  margin: 112px 0px 0px 0px;
  @media only screen and (max-width: 1780px) and (min-width: 1441px) {
    max-width: 250px;
  }
  @media (max-width: 1441px) {
    width: 300px;
  }
  @media (max-width: 1025px) {
    width: 300px;
  }
  @media (max-width: 426px) {
    width: 280px;
    height: 100%;
  }
  @media (max-width: 376px) {
    width: 250px;
    height: 100%;
  }
  @media (max-width: 321px) {
    height: 100%;
    width: 220px;
  }
`;
export const Img2 = styled.img`
  margin: 95px 0px 0px 32px;
  @media only screen and (max-width: 1780px) and (min-width: 1441px) {
    max-width: 250px;
  }
  @media (max-width: 1441px) {
    width: 300px;
  }
  @media (max-width: 1025px) {
    width: 300px;
  }
  @media (max-width: 426px) {
    width: 280px;
    height: 100%;
  }
  @media (max-width: 376px) {
    height: 100%;
    width: 250px;
  }
  @media (max-width: 321px) {
    height: 100%;
    width: 220px;
  }
`;

export const Img3 = styled.img`
  margin: 95px 0px 0px 0px;
  @media only screen and (max-width: 1780px) and (min-width: 1441px) {
    max-width: 250px;
  }
  @media (max-width: 1441px) {
    width: 300px;
  }
  @media (max-width: 1025px) {
    width: 300px;
  }
  @media (max-width: 426px) {
    width: 280px;
    height: 100%;
  }
  @media (max-width: 376px) {
    width: 250px;
    height: 100%;
  }
  @media (max-width: 321px) {
    height: 100%;
    width: 220px;
  }
`;

export const Img4 = styled.img`
  margin: 31px 0px 0px 0px;
  @media only screen and (max-width: 1780px) and (min-width: 1441px) {
    max-width: 250px;
  }
  @media (max-width: 1441px) {
    width: 300px;
  }
  @media (max-width: 1025px) {
    width: 250px;
  }
  @media (max-width: 426px) {
    width: 280px;
    height: 100%;
  }
  @media (max-width: 376px) {
    width: 250px;
    height: 100%;
  }
  @media (max-width: 321px) {
    height: 100%;
    width: 220px;
  }
`;

export const Img5 = styled.img`
  z-index: -1;
  margin: -70px -80px;
  @media only screen and (max-width: 1780px) and (min-width: 1441px) {
    max-width: 250px;
  }
  @media (max-width: 1441px) {
    width: 300px;
  }
  @media (max-width: 1025px) {
    width: 300px;
  }
  @media (max-width: 426px) {
    width: 280px;
    height: 100%;
    margin: -10px -20px;
  }
  @media (max-width: 376px) {
    width: 250px;
    margin: -10px -20px;
    height: 100%;
  }
  @media (max-width: 321px) {
    height: 100%;
    margin: -20px -30px;
    width: 220px;
  }
`;

export const Img6 = styled.img`
  margin: 48px 0px 0px 0px;
  @media only screen and (max-width: 1780px) and (min-width: 1441px) {
    max-width: 250px;
  }
  @media (max-width: 1441px) {
    width: 300px;
  }
  @media (max-width: 1025px) {
    width: 300px;
  }
  @media (max-width: 426px) {
    width: 280px;
    height: 100%;
  }
  @media (max-width: 376px) {
    width: 250px;
    height: 100%;
  }
  @media (max-width: 321px) {
    height: 100%;
    width: 220px;
  }
`;

export const Img7 = styled.img`
  margin: 96px 0px 0px 0px;
  @media only screen and (max-width: 1780px) and (min-width: 1441px) {
    max-width: 250px;
  }
  @media (max-width: 1441px) {
    width: 300px;
  }
  @media (max-width: 1025px) {
    width: 300px;
  }
  @media (max-width: 426px) {
    width: 280px;
    height: 100%;
  }
  @media (max-width: 376px) {
    width: 250px;
    height: 100%;
  }
  @media (max-width: 321px) {
    height: 100%;
    width: 220px;
  }
`;

export const Img8 = styled.img`
  margin: 220px 0px 0px 0px;
  @media only screen and (max-width: 1780px) and (min-width: 1441px) {
    max-width: 250px;
  }
  @media (max-width: 2500px) {
    width: 250px;
  }
  @media (max-width: 1025px) {
    width: 300px;
  }
  @media (max-width: 426px) {
    width: 280px;
    height: 100%;
    margin: 66px 0px 0px 0px;
  }
  @media (max-width: 376px) {
    width: 250px;
    height: 100%;
    margin: 50px 0px 0px 0px;
  }
  @media (max-width: 321px) {
    height: 100%;
    width: 220px;
    margin: 40px 0px 0px 0px;
  }
`;
