import styled from "styled-components";
import bgLines from "../../images/first-block-lines.png";

export const Container = styled.div`
  width: 100%;
  background: url(${bgLines});
  background-position: center center;
  background-repeat: no-repeat;
  padding-top: 90px;
  padding-bottom: 230px;
  position: relative;

  @media (max-width: 980px) {
    width: 100%;
    display: none;
  }
`;

export const HandContainer = styled.div`
  @media (max-width: 1300px) {
    margin-top: 10px;
  }
`;

export const ContainerMobile = styled.div`
  background: url(${bgLines});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 40px 0px 60px 0px;
  display: none;
  @media (min-width: 1300px) {
    display: none;
  }
  @media only screen and (max-width: 1300px) and (min-width: 781px) {
    width: 100%;
  }
  @media only screen and (max-width: 780px) and (min-width: 320px) {
    width: 100%;
    padding: 20px 0px 0px 0px;
  }
  /* @media only screen and (max-width: 1300px) and (min-width: 781px) {
    width: 100%;
  } */
`;

export const Heading = styled.h1`
  font-family: "Manrope";
  font-style: normal;
  font-weight: 800;
  font-size: 66px;
  line-height: 90px;
  text-align: center;
  color: #1c2541;
  text-align: center;
  margin-bottom: 25px;

  @media (max-width: 1300px) {
    font-size: 36px;
    line-height: 40px;
  }
  @media (max-width: 780px) {
    font-size: 24px;
    line-height: 40px;
  }
`;

export const TitleBg = styled.div`
  position: absolute;
  width: 142px;
  height: 39px;
  background: rgba(166, 97, 255, 0.3);
  top: 20px;
  margin: 10px 0 0 30px;
  left: 0;
  z-index: -1;
  @media (max-width: 780px) {
    width: 45px;
    height: 17px;
    top: 0;
    left: -10px;
  }
  @media only screen and (max-width: 1300px) and (min-width: 781px) {
    width: 70px;
    height: 30px;
    top: 0;
    left: -3px;
  }
`;
export const ButtonBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const GetStartedButton = styled.button`
  width: ${(props) => (props.w ? props.w : `147px`)};
  height: 45px;
  background: #36f599;
  border-radius: 23.1397px;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 23px;
  color: #1c2541;
  outline: none;
  border: none;
  cursor: pointer;
  @media (max-width: 780px) {
    width: ${(props) => (props.w ? props.w : `105px`)};
    line-height: 10px;
    font-size: 14px;
    background-color: ${(props) => props.bg && props.bg};
    color: ${(props) => props.bg && `white`};
    font-weight: ${(props) => props.bg && `700`};
    font-size: ${(props) => props.bg && `18px`};
  }
`;
export const LearnMore = styled.a`
  font-family: "Manrope";
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 23px;
  margin-left: 26px;
  display: flex;
  align-items: center;
  text-decoration: none;
`;
export const RightArrow = styled.img`
  margin-left: 11px;
  @media (max-width: 780px) {
    width: 18px;
    margin-left: 5px;
  }
  @media only screen and (max-width: 1300px) and (min-width: 781px) {
    width: 22px;
    margin-left: 8px;
  }
`;
export const Cards = styled.div`
  display: flex;
  align-items: center;
  // margin-top: 50px;
  margin-left: 450px;
  @media (max-width: 1919px) {
    margin-left: 240px;
  }
  @media (max-width: 1599px) {
    margin-left: 160px;
  }
  @media (max-width: 1439px) {
    margin-left: 60px;
  }
  @media (max-width: 780px) {
    margin: 0;
    justify-content: center;
    margin-left: 0px;
    margin-top: 20px;
  }
  @media only screen and (max-width: 1300px) and (min-width: 781px) {
    margin-top: 30px;
    padding-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
export const CardWrapper = styled.div`
  position: relative;
  width: 372px;

  @media only screen and (max-width: 780px) and (min-width: 481px) {
    width: 160px;
    // margin: 10px 0px 50px 0px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  @media only screen and (max-width: 480px) and (min-width: 320px) {
    width: 140px;
    // margin: 10px 0px 50px 0px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  /* @media (max-width: 780px) {
    width: 160px;
    // margin: 10px 0px 50px 0px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  } */

  /* 
  @media (max-width: 377px) {
    width: 140px;
    // margin: 10px 0px 50px 0px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  } */
`;
export const Card = styled.div`
  width: 100%;
  padding: 19px 15px 19px 16px;
  background: #ffffff;
  border: 1.5px solid #485470;
  border-radius: 9px;
  transform: rotate(${(props) => props.rotation});
  margin-bottom: ${(props) => props.bottomMinus};
  /* ${(props) => props.width && `width:370px`} */

  /* @media (max-width: 599px) {
    max-width: 150px;
    margin-top: 10px;
    margin-bottom: 0px;
    transform: rotate(5deg);
    padding: 10px;
  } */

  @media only screen and (max-width: 1300px) and (min-width: 781px) {
    width: 350px;
    margin: 10px;
    margin-top: ${(props) => props.margin};
  }
  @media only screen and (max-width: 780px) and (min-width: 600px) {
    width: 240px;
    margin-top: 10px;
    margin-bottom: 20px;
    transform: rotate(0);
    margin-right: 150px;
  }
  @media only screen and (max-width: 599px) and (min-width: 481px) {
    width: 215px;
    margin-top: 10px;
    margin-bottom: 0px;
    transform: rotate(5deg);
  }
  @media only screen and (max-width: 480px) and (min-width: 377px) {
    width: 150px;
    margin-top: 10px;
    margin-bottom: 0px;
    transform: rotate(5deg);
  }
  @media only screen and (max-width: 376px) and (min-width: 320px) {
    width: 130px;
    margin-top: 10px;
    margin-bottom: 0px;
    transform: rotate(5deg);
  }
`;

export const CardImageContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: 781px) and (min-width: 601px) {
    width: 220px;
    height: 220px;
  }
  @media only screen and (max-width: 600px) and (min-width: 481px) {
    width: 160px;
    height: 120px;
  }
  @media only screen and (max-width: 480px) and (min-width: 320px) {
    width: 150px;
    height: 80px;
  }
  /* @media (max-width: 780px) {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 120px;
  } */
`;
export const CardImg = styled.img`
  width: 100%;
  height: 338px;
  ${(props) => props.width && `width:85%`}
  /* @media (max-width: 780px) {
    width: 100px;
    height: 140px;
    padding: 0px;
    margin: 0px;
  } */
  @media only screen and (max-width: 1300px) and (min-width: 781px) {
    width: 300px;
    height: 300px;
  }
  @media only screen and (max-width: 780px) and (min-width: 601px) {
    width: 200px;
    height: 200px;
  }
  @media only screen and (max-width: 600px) and (min-width: 481px) {
    width: 160px;
    height: 120px;
  }
  @media only screen and (max-width: 480px) and (min-width: 320px) {
    width: 120px;
    height: 80px;
  }
`;
export const CardTitleSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 13px;
  margin-bottom: 8px;
  @media (max-width: 780px) {
    margin-top: 0px;
    flex-direction: row;
    align-items: flex-start;
    margin-top: 10px;
  }
`;
export const CardTitle = styled.h1`
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  font-size: 21px;
  line-height: 29px;
  color: #485470;
  margin: 0;

  @media (max-width: 780px) {
    font-size: 15px;
    line-height: 15px;
  }
  @media only screen and (max-width: 480px) and (min-width: 320px) {
    font-size: 8px;
    line-height: 12px;
  }
  /* 
  @media (max-width: 426px) {
    font-size: 10px;
    line-height: 15px;
  }
  @media (max-width: 376px) {
    line-height: 10px;
    font-size: 8px;
  }
  @media (max-width: 321px) {
    line-height: 10px;
    font-size: 8px;
  } */
`;
export const CardSold = styled.h6`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #99a7c7;
  margin: 0;
  @media (max-width: 780px) {
    font-size: 10px;
  }
  @media (max-width: 376px) {
    font-size: 10px;
  }
  @media (max-width: 321px) {
    font-size: 8px;
  }
`;
export const CardUser = styled.h5`
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: #485470;
  margin: 0;
  display: flex;
  align-items: center;
  @media (max-width: 780px) {
    font-size: 10px;
  }
  @media (max-width: 376px) {
    font-size: 8px;
  }
  @media (max-width: 321px) {
    font-size: 8px;
    line-height: 10px;
  }
`;
export const CardUserStar = styled.img`
  margin-left: 3px;
`;
export const CardButton = styled.button`
  background: #ffffff;
  border: 1px solid #485470;
  border-radius: 23.1397px;
  width: 178.67px;
  height: 44px;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 23px;
  color: #485470;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 14px 0px 20px;
  margin-top: 19px;
  cursor: pointer;
  @media (max-width: 780px) {
    width: 130px;
    font-size: 10px;
    line-height: 13px;
    height: 35px;
  }
  @media (max-width: 426px) {
    width: 130px;
    font-size: 10px;
    line-height: 13px;
    height: 35px;
  }
  @media (max-width: 376px) {
    width: 110px;
    font-size: 10px;
    line-height: 13px;
    height: 30px;
  }
`;
export const HandImage = styled.img`
  position: absolute;
  bottom: 0;
  right: 150px;

  @media (max-width: 1919px) {
    right: 75px;
    height: 800px;
  }
  @media (max-width: 1599px) {
    right: 20px;
    height: 750px;
  }
  @media (max-width: 1439px) {
    right: 0;
    height: 700px;
  }

  @media only screen and (max-width: 1300px) and (min-width: 781px) {
    width: 350px;
    height: 500px;
    position: relative;
    // transform: rotate(6deg);
    margin-top: -250px;
    margin-left: 30px;
  }

  @media only screen and (max-width: 780px) and (min-width: 400px) {
    width: 250px;
    height: 260px;
    position: absolute;
    transform: rotate(6deg);
    right: -15px;
    margin-top: 0px;
  }

  @media (max-width: 399px) {
    width: 153px;
    margin-top: 20px;
    height: 269px;
    position: relative;
    transform: rotate(6deg);
    right: 0px;
  }
`;

// card mobile view

export const CardSectionConatiner = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: url(${bgLines});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 40px 0px 0px 0px;
  @media only screen and (max-width: 13000px) and (min-width: 981px) {
    display: none;
  }
`;

export const TopHeaderSection = styled.div`
  width: 100%;
`;

export const CenterSection = styled.div`
  width: 100%;
`;

export const ImageCard = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 30px auto;
`;
export const ImageCardContent = styled.div`
  width: 40%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: 981px) and (min-width: 781px) {
    width: 45%;
    height: 350px;
  }
  @media only screen and (max-width: 780px) and (min-width: 481px) {
    width: 43%;
    height: 300px;
  }
`;
export const HandCardContent = styled.div`
  width: 40%;
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: 981px) and (min-width: 781px) {
    width: 45%;
    height: 350px;
  }
  @media only screen and (max-width: 780px) and (min-width: 481px) {
    width: 45%;
    height: 320px;
  }
  @media only screen and (max-width: 480px) and (min-width: 320px) {
    width: 45%;
    height: 300px;
  }
`;
export const HandImageSection = styled.img`
  width: 100%;
  height: 100%;
  transform: rotate(6deg);
  margin-left: 20px;
  margin-top: 60px;
`;
export const ImageCardItem = styled.div`
  width: 200px;
  height: 100%;
  display: flex;
  /* align-items: center;
  justify-content: center; */
  flex-direction: column;
  background: #ffffff;
  border: 1.5px solid #485470;
  border-radius: 9px;
  transform: matrix(0.98, 0.17, -0.18, 0.99, 0, 0);
  @media only screen and (max-width: 981px) and (min-width: 781px) {
    width: 270px;
    height: 100%;
  }
  @media only screen and (max-width: 780px) and (min-width: 481px) {
    width: 230px;
    height: 100%;
  }
`;
export const CardImage = styled.img`
  width: 100%;
  height: 60%;
  padding: 10px;
  @media only screen and (max-width: 981px) and (min-width: 781px) {
    width: 100%;
    height: 60%;
  }
  @media only screen and (max-width: 780px) and (min-width: 481px) {
    width: 100%;
    height: 60%;
  }
  @media only screen and (max-width: 480px) and (min-width: 320px) {
    width: 100%;
    height: 40%;
  }
`;
export const CardAuthorDiv = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
`;
export const CardAuthor = styled.span`
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  font-size: 21px;
  line-height: 29px;
  color: #485470;
  @media only screen and (max-width: 780px) and (min-width: 481px) {
    font-size: 15px;
    line-height: 29px;
  }
  @media only screen and (max-width: 480px) and (min-width: 320px) {
    font-size: 9px;
    line-height: 10px;
  }
`;
export const CardAuthorPrice = styled.span`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height */
  color: #99a7c7;
  @media only screen and (max-width: 780px) and (min-width: 481px) {
    font-size: 10px;
    line-height: 29px;
  }
  @media only screen and (max-width: 480px) and (min-width: 320px) {
    font-size: 7px;
    line-height: 10px;
  }
`;
export const SubTitle = styled.span`
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  /* identical to box height */
  color: #485470;
  padding: 5px;
  @media only screen and (max-width: 480px) and (min-width: 320px) {
    font-size: 10px;
    line-height: 20px;
  }
`;
export const ExploreButtonDiv = styled.div`
  width: 90%;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #ffffff;
  border: 1px solid #485470;
  border-radius: 23.1397px;
  margin: 10px auto;
`;
export const ButtonWrapper = styled.span`
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 23px;
  color: #485470;
  @media only screen and (max-width: 780px) and (min-width: 481px) {
    font-size: 14px;
    line-height: 15px;
  }
  @media only screen and (max-width: 480px) and (min-width: 320px) {
    font-size: 10px;
    line-height: 10px;
  }
`;
// export const CenterSection = styled.div``;
// export const CenterSection = styled.div``;
// export const CenterSection = styled.div``;
// export const CenterSection = styled.div``;
// export const CenterSection = styled.div``;
